<app-top-header class='top-bar-container' bgStyle="solid" screenTitle="Delivery Addresses"></app-top-header>
<div class="page-content">
  <div class="menu-block">
    <div iknLoader [isActive]='isLoading' class="loader-container"></div>
    <div class="margin-top-10"></div>
    <h6 class="font-extraBold text-center" *ngIf="!lstAddresses?.length && !isLoading">
      <div class="m-t-20"></div>
      {{'No Saved Addresses' | translate}}
    </h6>
    <div class="row align-items-center font-bold no-margin" [ngClass]="{'text-link' : !!nextUrl, 'cursor-pointer': type == 'select'}"
      *ngFor="let address of lstAddresses" (click)="selectAddress(address)">

      <div class="column w-100">
        <div class="card-track w-100">
          <div class="d-flex justify-content-between w-100">
            <div class='w-100'>
              <div class="card-track-status w-100">
                <div class='d-flex justify-content-between  align-items-between w-100 color-primary'>
                <div class="">
                  {{'Address Title' | translate}}: {{address.address.title}}
                </div>
                <div class="mx-1">
                  <a class="color-primary" [routerLink]="['/addresses/' + address.id + '/edit']"
                    [queryParams]="{next: nextUrl}">{{'Edit' | translate}}</a>
                  <!-- <i class="fab fa-trash text-link text-danger" (click)="removeAddress(address)"></i> -->
                </div>
              </div>
            </div>

              <div class="card-track-data" >
                {{'Area'| translate}}: {{address.area?.name | iknLocalized}}<br />
                {{'Street'| translate}}: {{address.address.street}}<br />
                {{'Block' | translate}}: {{address.address.blockNo}}<br />
                {{'House/Building' | translate}}: {{address.address.buildingNo}}<br />
                {{'Phone' | translate}}: <span class="reversed-phone">{{address.address.contactPhone}}</span>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-center px-2" *ngIf="type=='select'">
              <span><i class="fa fa-angle-right fa-2x"></i></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="margin-top-10"></div>
    <div class="m-t-20"></div>
    <div class="cart-shop text-center color-primary font-extraBold">
      <a [routerLink]="['/']" class="color-primary m-r-5">{{'Continue Shopping' | translate}}</a>
      <span class="m-r-5" *ngIf="nextUrl && lstAddresses?.length"> | </span>
      <a [routerLink]="['/addresses/new']" [queryParams]="{next: nextUrl}" class="color-primary"
        *ngIf="nextUrl && lstAddresses?.length">{{'Add Address' | translate}}</a>
    </div>

  </div>
</div>
<div class="page-btn font-bold" *ngIf="type != 'select'">
  <a class="btn btn-primary" *ngIf="!nextUrl || !lstAddresses?.length" [routerLink]="['/addresses/new']"
    [queryParams]="{next: nextUrl}">
    {{'Add Address' | translate}}
  </a>
  <a class="btn btn-primary" *ngIf="nextUrl && lstAddresses?.length" (click)="checkout()">
    {{'Deliver to selected address' | translate}}
  </a>
</div>

<div class="page-btn font-bold" *ngIf="type == 'select'">
    <a class="btn btn-primary" *ngIf="!nextUrl || !lstAddresses?.length" [routerLink]="['/addresses/new']"
      [queryParams]="{next: nextUrl}">
      {{'Add Address' | translate}}
    </a>
  </div>
<app-footer></app-footer>
