import { TranslateService } from '@ngx-translate/core';
import { OwlDateTimeIntl } from 'ng-pick-datetime';
import { Injectable } from "@angular/core";


@Injectable()
export class DefaultIntl extends OwlDateTimeIntl {

  static instance:DefaultIntl;
  static _trans:TranslateService
  constructor(){
    super();
    DefaultIntl.instance = this;
    DefaultIntl.langUpdated(null);
  }
  /** A label for the cancel button */
  cancelBtnLabel= 'Cancel';

  /** A label for the set button */
  setBtnLabel= 'Choose';

  /** A label for the hour12 button (AM) */
  hour12AMLabel= 'AM';

  /** A label for the hour12 button (PM) */
  hour12PMLabel= 'PM';

  static langUpdated(_trans:TranslateService){
    _trans = _trans || DefaultIntl._trans;
    if(!DefaultIntl._trans)
      DefaultIntl._trans = _trans;
    if(!this.instance)
      return;
    this.instance.cancelBtnLabel= _trans.instant('Cancel');

    /** A label for the set button */
    this.instance.setBtnLabel= _trans.instant('Choose');

    /** A label for the hour12 button (AM) */
    this.instance.hour12AMLabel= _trans.instant('AM');

    /** A label for the hour12 button (PM) */
    this.instance.hour12PMLabel= _trans.instant('PM');
  }
};
