<div class="ikn-modal area-selector">
    <ngx-smart-modal #mSelectArea identifier="mSelectArea">
        <div class="comp-area">
            <!-- <button class="dismiss-btn" (click)="modal.getModal('mSelectArea').close()">
              <i class="fa fa-times"></i>
            </button> -->
            <!-- <div class="m-t-30"></div> -->
            <h1 class="ikn-modal-header font-extraBold">
                {{'Choose Your Delivery Location' | translate}}
            </h1>

            <div class='col'>
                <div class="wrap font-extraBold">
                    <div class="search">
                        <button type="button" class="search_button">
                            <i class="fa fa-map-marker"></i>
                        </button>
                        <input type="text" class="search_term" placeholder="{{'Search for area' | translate}}" [(ngModel)]="searchText">
                    </div>
                </div>

                <!-- <div class="input-wrapper ">
                  <input type="text" class="" placeholder="" [(ngModel)]="searchText">
                  <i class="im im-search"></i>
                </div> -->
            </div>
            <div class='area-selector-container font-extraBold'>
                <div name="selected" *ngFor="let governate of governates">
                    <div *ngIf="(governate.areas | filter:searchText) as results">
                        <div class="governate" (click)="onGovernate(governate)" [ngClass]="{'active': governate.flag}" *ngIf="results.length">
                            <i class="anglePosition" [ngClass]="{'im im-angledown': !governate.flag && !searchText}"></i>
                            <i class="anglePosition" [ngClass]="{'im im-angleup': governate.flag || searchText}"></i>
                            <a class="governatename ar-right">{{governate.name | iknLocalized}} </a>
                        </div>
                        <div *ngIf="governate.flag || searchText">
                            <p class="area text-right" *ngFor="let area of results"
                               (click)="onSave(area)">
                                <a class="areaname">{{area.name | iknLocalized}}</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ngx-smart-modal>
</div>

<!--<button [class.btn-outline-secondary]="areaObj" [class.btn]="areaObj" (click)="selectArea()" [style.background-color]="'red'" [style.border-color]="areaObj ? '#817683' : 'red'" style="color: white">{{ (areaObj ? 'Change Area' : 'Select Area') | translate }}</button>-->
<a *ngIf="areaObj" class="btn btn-primary bg-primary btn-cart" (click)="selectArea()" >{{ (areaObj ? 'Change Area' : 'Select Area') | translate }}</a>
<a *ngIf="!areaObj" class="btn btn-primary bg-primary btn-cart" style="background-color: red !important" (click)="selectArea()">{{ (areaObj ? 'Change Area' : 'Select Area') | translate }}</a>


