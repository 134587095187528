<div class="page-btn font-extraBold" *ngIf='cart?.cartItems?.length' routerLink='/cart'>
    <a href="javascript:{}"
       class="btn btn-primary d-flex justify-content-between align-items-center">

        <div>{{cart?.$totalItemsQty}}</div>
        <div class='flex-grow-1 text-center'>{{'Review Cart' | translate}}</div>
        <div>{{cart?.$subTotal | iknCurrency}} {{cart?.$truck?.defaultCountry?.currencyCode | iknLocalized}}</div>

    </a>
</div>
<app-top-header class="top-bar-container" [bgStyle]="'solid'" [screenTitle]="category?.categoryInfo?.title | iknLocalized"></app-top-header>
<div class="cat-clearfix">
    &nbsp;
</div>
<!-- <div class="cat-main-pic" *ngIf="category?.categoryInfo?.mainPic">
    <img [src]="category?.categoryInfo?.mainPic" />
</div> -->
<div class="page-content">
    <div iknLoader [isActive]='!truck' class="loader-container"></div>
    <div *ngFor="let item of items | filter: searchText">
        <div>
            <app-menu-item-card [item]="item"></app-menu-item-card>
        </div>
    </div>
</div>
<app-extra-category-alert [(deliveryCharge)]="category?.categoryInfo.extraDeliveryCharge"
                          [(deliveryTime)]="category?.categoryInfo.extraDeliveryTimeMinutes" class="pull-heading-right">
</app-extra-category-alert>
<app-footer></app-footer>
<br />
<br />
