import { Component, Input, OnInit } from '@angular/core';
import { ApiResult, Area, governate, LocalizedField, TruckInfo } from '../models';
import { ApiService } from '../api.service';
import { UtilsService } from '../utils.service';
import { CartService } from '../cart.service';
import { Cart } from '../cart';
import * as _ from 'lodash';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-part',
  templateUrl: './login-part.component.html',
  styleUrls: ['./login-part.component.css']
})
export class LoginPartComponent implements OnInit {
  @Input()
  returnTo: string[] = ['/'];
  truck: TruckInfo;
  cart: Cart;
  username: string;
  password: string;
  loginForm: FormGroup;
  isLoading: boolean = false;
  constructor(private api: ApiService, private srvCart: CartService,
    private router:Router,
    public utils: UtilsService, formBuilder: FormBuilder) {
    this.loginForm = formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],

    });
  }

  ngOnInit() {
  }

  login() {
    if(this.isLoading)
      return;
    this.isLoading = true;
    this.api.login({
      userName: this.username,
      password: this.password
    }).subscribe(d=>{
      this.api.authUser(d);
      if(this.returnTo && this.returnTo.length)
        this.router.navigate(this.returnTo);
      else
        this.utils.goBack();
    }, d=>{
      this.utils.error(d);
      this.isLoading = false;
    })
  }
}
