<ngx-smart-modal #mSelectSubstitution identifier="mSelectSubstitution" [dismissable]="false">
  <div class="comp-substitution">
    <button class="dismiss-btn color-primary" (click)="onSave()">
        {{'Confirm'|translate}}
    </button>
    <div class="margin-top-10"></div>
    <h1 class="font-extraBold">
      {{'Substitutions' | translate}}
    </h1>
    <div class="margin-top-10"></div>
    <p>{{'substitution_txt' | translate}}</p>
    <div class="margin-top-10"></div>
    <div class="area-selector-container">
        <div class="">
            <div class="governate" >
                <input class="radio" [(ngModel)]="selectedSubstituation" id="recommend"
                  type="radio" value="recommend">
                <label for="recommend">{{'recommend' | translate}}</label>
                <div class="m-b-5"></div>
            </div>
            <div class="margin-top-10"></div>
            <div class="governate" >
                <input class="radio" [(ngModel)]="selectedSubstituation" id="leaveItem"
                  type="radio" value="leaveItem">
                <label for="leaveItem">{{'leaveItem' | translate}}</label>
                <div class="m-b-5"></div>
            </div>
        </div>
    </div>
  </div>
  </ngx-smart-modal>

<span (click)="selectSubstitution()" class="area-label">
    <span class="color-primary">{{ 'Change' | translate }}</span>
<span>
