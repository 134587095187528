import { Component, OnInit } from '@angular/core';
import { ApiResult, LocalizedField } from '../models';
import { ApiService } from '../api.service';
import { UtilsService } from '../utils.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
   aboutUs : LocalizedField;
  constructor(apiServce : ApiService , public utils:UtilsService) { 
    apiServce.viewAboutUs().subscribe(res => {
      this.aboutUs = res.aboutUs;
      console.log(this.aboutUs);
    });
  }
  
  ngOnInit() {
  }

}
