<app-top-header class='top-bar-container' bgStyle="solid" screenTitle="Time And Date"></app-top-header>
<div class="page-content " *ngIf="!isLoading">
    <div iknLoader [isActive]='isLoading' class="loader-container"></div>
    <div class="wrap mt-5">
        <div class="d-inline-flex border-top bc-primary border-bottom w-100 " style="overflow-x:scroll">
            <div class="selectable text-nowrap text-center f-bold px-2 py-2" *ngFor="let date of dates"
                [ngClass]="{'active': selectedDate == date}" (click)="setDate(date)">
                <div>{{date | iknDate:'dm'}}</div>
                <div class="f-bolder mt-1">{{date | iknDate: 'day'}}</div>
            </div>
        </div>
    </div>
    <div class='px-4'>
        <div iknLoader [isActive]='isLoadingSlots' class="loader-container"></div>
        <div class='mt-4 t-small color-primary' *ngIf='cart?.serviceType_ex == "catering"'>
            {{'Note: Catering is for (3 Hours)' | translate}}
        </div>
        <div class="mt-4" *ngIf="!isLoadingSlots">
            <div class="py-2 d-flex align-items-center t-medium f-bold" *ngFor="let slot of slots">
                <input class="radio" [(ngModel)]="selectedSlotId" [id]="'t_' + slot.deliveryTime.id"
                    [disabled]="!slot.isAvailable" (change)="setSlot()" type="radio" [value]="slot.deliveryTime.id">
                <label class="m-0" [for]="'t_' + slot.deliveryTime.id" [class.text-strike]="!slot.isAvailable">
                    <span class="" [class.text-strike]="!slot.isAvailable">
                        <!-- {{'From' | translate}} -->
                        {{slot.from | iknDate:'tf'}} -
                        <!-- {{'to'|translate}} -->
                        {{slot.to | iknDate:'tf'}}
                    </span>
                </label>
            </div>
        </div>
    </div>
</div>
<div class="" *ngIf="!isLoading && selectedSlot" class="page-btn font-bold">
    <a class="btn btn-primary bg-primary pull-heading-right t-medium" (click)="save()">
        {{'Choose Time' | translate}} -
        {{selectedSlot?.from | iknDate: 'day'}} {{selectedSlot?.from | iknDate: 'dm'}}
        <span *ngIf='!dateOnly'>
            {{selectedSlot?.from | iknDate: 'tf'}}
            <span *ngIf='selectedSlot?.to'> - {{selectedSlot?.to | iknDate: 'tf'}}</span>
        </span>
    </a>
    <div class="clearfix"></div>
</div>
<app-footer></app-footer>