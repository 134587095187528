import { Component, EventEmitter, OnInit, OnDestroy, ViewChild, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { CartService } from '../cart.service';
import { UtilsService } from '../utils.service';
import { ToastrService } from 'ngx-toastr';
import { Cart } from '../cart';
import { AreaComponent } from '../area/area.component';
import { Address, CountryListDto, governate, Area, AppEventType } from '../models';
import { environment } from 'src/environments/environment';
import { ApiService } from '../api.service';
import * as _ from 'lodash'
import { Router } from '@angular/router';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { TranslateService } from '@ngx-translate/core';
import { CountrySelectorComponent } from '../country-selector/country-selector.component';

@Component({
    selector: 'app-address-part',
    templateUrl: './address-part.component.html',
    styleUrls: ['./address-part.component.css']
})
export class AddressPartComponent implements OnInit, OnDestroy {

    addressForm: FormGroup;
    submitted = false;
    isLoading: boolean = false;
    isFirstTimeLoadCountry = true;
    contactPhoneLengthError = false;
    isShippingMode: boolean = false;
    cart: Cart;
    governates: governate[] = [];
    pickupAddress: Address = {
        countryId: -1,
        areaId: -1,
        governateId: -1,
        contactPhone: '',
    }
    countries: CountryListDto[] = [];
    areas: Area[] = [];
    area: Area;
    showArea = environment.show_area;
    areaRequired = environment.area_required;
    allowShipping = environment.allow_shipping;
    defaultCountry = environment.default_country;
    allowMembershipNumber = environment.allow_membership_number;
    isPickupServiceSelected: boolean = true;
    SearchCountryField = SearchCountryField;
    TooltipLabel = TooltipLabel;
    CountryISO = CountryISO;
    preferredCountries: CountryISO[] = [CountryISO.Kuwait, CountryISO.Qatar];
    phoneNumber: any;
    selectedCountryCode: string;
    latitude: number; longitude: number;
    @ViewChild(AreaComponent) areaSelector: AreaComponent;
    @ViewChild(CountrySelectorComponent, { static: true }) countrySelector: CountrySelectorComponent;
    @ViewChild('frmAddress') frmAddress: NgForm;
    @Input() returnTo: string;
    @Output() areaChanged: EventEmitter<number> = new EventEmitter<number>();
    pickupIcon: any = {
        icon: {
            url: '../../assets/images/markers/map-marker2.svg',
            scaledSize: { width: 41, height: 41 }
        }
    };
    country: any;
    governate: any;
    constructor(private srvCart: CartService, public utils: UtilsService, private router: Router, private api: ApiService, private toaster: ToastrService, private formBuilder: FormBuilder, private trans: TranslateService) {
        this.addressForm = this.formBuilder.group({
            contactName: [''],
            contactPhone: ['', Validators.required],
            blockNo: ['', Validators.required],
            avenue: [''],
            street: ['', Validators.required],
            buildingNo: ['', Validators.required],
            floorNo: ['', Validators.required],
            flatNo: ['', Validators.required],
            //addressLine1: [''],
            title: [''],
            instructions: [''],
            countryId: [''],
            governateId: [''],
            areaId: [''],
            membershipNumber: [''],
        });
        this.cart = srvCart.getCart();
        this.pickupAddress = this.cart.orderInfo.pickupAddress || { location: {} };
        this.pickupAddress.location = this.pickupAddress.location || {};
        this.saveType(this.pickupAddress.type || 'house');
        this.requestLocation();
        this.phoneNumber = this.pickupAddress.contactPhone;
        this.selectedCountryCode = this.pickupAddress.countryCode || (this.cart.$truck && this.cart.$truck.defaultCountry && this.cart.$truck.defaultCountry.code2) || 'kw';

        if (this.cart.$truck) {
            this.isPickupServiceSelected = this.cart.$truck.services.includes('pickup');
            this.allowShipping = this.cart.$truck.allowShippingOutsideCountry;
        }
    }
    ngOnInit() {
        this.utils.fbTrack('ConfirmAddress');
        this.isLoading = true;
        this.api.viewTruck().subscribe(t => {
            this.defaultCountry = t.truck.defaultCountry && t.truck.defaultCountry.id;
            this.isPickupServiceSelected = t.truck.services.includes('pickup');
            this.allowShipping = t.truck.allowShippingOutsideCountry;
            this.governate = this.cart.orderInfo.pickupAddress.governateName;
            if (this.allowShipping && this.defaultCountry != this.pickupAddress.countryId) {
                this.isShippingMode = true;
            }
            this.country = this.cart.orderInfo.pickupAddress.countryName;
            if (!this.country) {
                this.country = t.truck.defaultCountry && t.truck.defaultCountry.name;
            }
            console.log(this.country);
            debugger;
            this.areas = t.truck.areas;
            this.area = _.find(this.areas, d => d.id == this.pickupAddress.areaId);

            this.api.viewSBOptionsDetails().subscribe(t => {
                this.api.getGovernate().subscribe((data) => {
                    let self = this;
                    _.each(data.items, gov => {
                        gov.areas = _.filter(gov.areas, a => _.some(t.areas,
                            function (ta) {
                                if ((self.pickupAddress.areaId == ta.area.id) && a.id == ta.area.id) {
                                    if (!self.pickupAddress.location) {
                                        self.latitude = ta.area.location.lat;
                                        self.longitude = ta.area.location.lng;
                                    }
                                    else {
                                        self.latitude = self.pickupAddress.location.lat;
                                        self.longitude = self.pickupAddress.location.lng;

                                    }
                                }

                            }));
                    })

                })
            })
            if (this.allowShipping && !this.pickupAddress.countryId)
                this.pickupAddress.countryId = this.defaultCountry;
            if (this.pickupAddress.countryId) {
                if (this.pickupAddress.countryId != environment.default_country) {
                    this.showArea = false;
                    this.areaRequired = false;
                }
                else {
                    this.showArea = environment.show_area;
                    this.areaRequired = environment.area_required;
                }


            }
            this.isLoading = false;
            console.log(this.allowShipping);

        });
        //$('.page-side').removeClass('sm-hidden');

        if (!this.cart.orderInfo.pickupAddress.areaId) {

        }

    }
    ngOnDestroy() {
        //$('.page-side').addClass('sm-hidden')
    }
    onAreaSelected(areaId) {
        this.areaChanged.emit(areaId);
        this.api.viewSBOptionsDetails().subscribe(t => {
            this.api.getGovernate().subscribe((data) => {
                let self = this;
                _.each(data.items, gov => {
                    gov.areas = _.filter(gov.areas, a => _.some(t.areas,
                        function (ta) {
                            if ((self.pickupAddress.areaId == ta.area.id) && a.id == ta.area.id) {
                                self.latitude = ta.area.location.lat;
                                self.longitude = ta.area.location.lng;
                            }

                        }));
                })

            })
        })
    }
    setAddress(address: Address, area: Area = null) {
        
        this.pickupAddress = address;
        this.area = _.find(this.areas, d => d.id == this.pickupAddress.areaId);
        this.area = this.area || area;

        this.saveType(this.pickupAddress.type || 'house');
    }
    storeAddress() {
        //TODO: we should have a flag to indicate whether we should apply this changes to cart or not
    }
    validatePhoneNumber() {
        if(this.phoneNumber &&this.phoneNumber.countryCode == "KW"){
        if (!this.phoneNumber?.number || 
            this.phoneNumber?.number?.replace(/\s/g, "").length != 8
             || this.phoneNumber?.number?.replace(/\s/g, "").startsWith("0")
             || this.phoneNumber?.number?.replace(/\s/g, "").startsWith("1")
             || this.phoneNumber?.number?.replace(/\s/g, "").startsWith("3")
             || this.phoneNumber?.number?.replace(/\s/g, "").startsWith("7")
             || this.phoneNumber?.number?.replace(/\s/g, "").startsWith("8")) {
            
            return true;
        }
        this.addressForm.controls['contactPhone'].setErrors(null);
        return false;
    }
}

    // convenience getter for easy access to form fields
    get f() { return this.addressForm.controls; }


    saveType(type: string) {
        this.pickupAddress.type = type;
        if (type == 'house') {
            this.addressForm.get("floorNo").disable();
            this.addressForm.get("flatNo").disable();
        } else {
            this.addressForm.get("floorNo").enable();
            this.addressForm.get("flatNo").enable();
        }
        this.pickupAddress.title = type;
    }
    public saveAddress(saveTo: Address) {
        
        this.validatePhoneNumber();
        
        this.frmAddress.ngSubmit.emit();
        this.submitted = true;
        if (this.addressForm.invalid ) {
            this.utils.scrollToError();
            return false;
        }
        
        if (this.phoneNumber) {
            this.pickupAddress.contactPhone = this.phoneNumber.internationalNumber;
            this.pickupAddress.countryCode = this.phoneNumber.countryCode;
        }
        if (this.pickupAddress.type == 'house') {
            this.addressForm.get("floorNo").disable();
            this.addressForm.get("flatNo").disable();
        } else {
            this.addressForm.get("floorNo").enable();
            this.addressForm.get("flatNo").enable();
        }
        if (!saveTo)
            this.srvCart.setAddress(this.pickupAddress);
        else
            _.assignIn(saveTo, this.pickupAddress);
        return true;
    }
    goBack() {
        if (this.returnTo)
            this.router.navigate([this.returnTo], { skipLocationChange: false, replaceUrl: true });
        else
            this.utils.goBack();
        //this.router.navigateByUrl('/cart');
    }

    requestLocation() {
        if ((!this.pickupAddress.location.lat || !this.pickupAddress.location.lng) && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position: Position) => {
                if (position) {
                    this.pickupAddress.location.lat = position.coords.latitude;
                    this.pickupAddress.location.lng = position.coords.longitude;
                }
            })
        }
    }

    openCountry() {
        this.countrySelector.selectCountry(() => {
            this.country = this.cart.orderInfo.pickupAddress.countryName;
        });
    }

    openCity() {
        this.countrySelector.selectCity(() => {

        });
    }
    openArea() {
        this.areaSelector.selectArea(() => {


        });
    }
    onCountryChange(countryId) {
        if (countryId != this.defaultCountry) {
            this.isShippingMode = true;
            this.showArea = false;
        }
        else {
            this.isShippingMode = false;
            this.showArea = true;
        }
        //open another popup auto
        if (this.showArea)
            this.openArea();
        else {
            if (this.country != this.cart.orderInfo.pickupAddress.countryName)
                this.governate = undefined;
            setTimeout(() => this.openCity(), 500);
        }
    }


}
