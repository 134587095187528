<app-top-header class="top-bar-container" bgStyle="solid" screenTitle="Track order"></app-top-header>
<div>
    <form [formGroup]="submitForm" (ngSubmit)="submitTrackOrder()">
        <div class="page-heading">
            <div class="heading-l">
                <div class="title">
                    <a class="back color-primary" (click)="onBack()">
                        <i class="fa fa-chevron-left"></i>
                    </a>
                </div>
            </div>
            <div class="heading-l heading-c">
                <div class="title font-extraBold">{{'Track Order' | translate}}</div>
            </div>
            <div class="heading-r"></div>
        </div>
        <div iknLoader [isActive]='isLoading' class="loader-container"></div>
        <div class="page-content">
            <div class="checkout-block">
                <div class="form-group">
                    <div class="form-group-body">
                        <div class="input-group">
                            <div class="search">


                                <input type="text" required class="search_term t-center font-extraBold"
                                       placeholder="{{'Order Number - Phone Number' | translate}}"
                                       [(ngModel)]="orderNo" formControlName="orderNo" />
                                <button class="btn btn-submit d-inline text-center" (click)="submitTrackOrder()">{{'Track Order' | translate}} </button>
                            </div>

                            <div class="m-t-20"></div>


                            <div *ngIf="submitted && f.orderNo.errors" class="invalid-feedback">
                                <div *ngIf="f.orderNo.errors.required">
                                    {{'Order Number is required'|translate}}
                                </div>
                            </div>
                        </div>

                        <div class="row" *ngIf="!(f.orderNo.errors&& notFoundRes)">
                            <div class="col respons-col" *ngIf="deliveryStateList">
                                <div *ngFor="let d of deliveryStateList">

                                    <div class="tabs order-track" >
                                        <div class="tab">
                                            <input class="check" type="checkbox" [id]="d.order.orderNo" name="order-tab" (change)="assignvalue(d)">

                                            <label class="tab-label" [for]="d.order.orderNo">
                                                <table style="width: 100%;">
                                                    <tr>
                                                        <td>
                                                            <span style="font-weight: 600;">{{d.order.orderNo}}&#160;&#160;&#160;</span>
                                                            {{('srv_'+d.order?.serviceType+'_service') | translate}}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <span> {{'Order Date' | translate}} :</span>  {{d.acceptedAt | iknDate:'dmy'}}
                                                        </td>
                                                        <td class="text-right">
                                                            <span>  {{'Order Time' | translate}} :</span>{{d.acceptedAt | iknDate:'tf'}}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span> {{'Pickup Date' | translate}} :</span> {{(d.order.pickupTime || d.order.expectedDeliveryTime) |iknDate:'dmy'}}
                                                        </td>
                                                        <td class="text-right">
                                                            <span> {{'Pickup Time' | translate}} :</span>  {{(d.order.pickupTime || d.order.expectedDeliveryTime) |iknDate:'tf'}}
                                                            <span style="font-weight: unset" *ngIf="d.order.pickupTimeTo || d.order.expectedDeliveryTimeTo">
                                                                {{'to' | translate}}
                                                                {{(d.order.pickupTimeTo || d.order.expectedDeliveryTimeTo) |iknDate:'tf'}}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr class="">
                                                        <td><a [routerLink]="['/order-details', d.order.id]">{{'Order Details'| translate}}</a></td>
                                                        <td><a class="cart-box-btn tab-label p-0">{{'Track Order'| translate}}</a></td>


                                                    </tr>
                                                    <tr class="response-text-center">
                                                       

                                                        <td class="td-align-right" *ngIf="d.order?.serviceType=='pickup'">
                                                            <a target="blank"
                                                               [href]="'https://www.google.com/maps/search/?api=1&query=' + deliveryState.order.truckLocation?.location.lat + ',' + deliveryState.order.truckLocation?.location.lng">
                                                                {{'Get Directions' | translate}}

                                                                <i class="text-success fa fa-map-marker"></i>
                                                            </a>
                                                        </td>

                                                    </tr>
                                                </table>

                                            </label>

                                            <div class="tab-content tracking-path">
                                                <div class="m-t-20"></div>
                                                <div *ngIf="successRes && !isCanceled" class="p-t-1  tracking-line">

                                                    <div style="clear: both;"></div>
                                                    <div>
                                                        <div class="d-flex  left-margin align-items-center font-bold">

                                                            <div class="main-track-circle" [class.inner-track-circle]="isPassedStatus('Accepted')">

                                                                <i *ngIf="isPassedStatus('Accepted')" class="fa fa-check"></i>
                                                            </div>
                                                            <div class="column w-100">
                                                                <div class="card-track w-100">
                                                                    <div class="card-track-status">

                                                                        <div class="img-icon">
                                                                            <img src="../../assets/images/1.png" *ngIf="! isPassedStatus('Accepted')">
                                                                            <img src="../../assets/images/1b.png" *ngIf="isPassedStatus('Accepted')">
                                                                        </div>
                                                                        <div class="j-start">
                                                                            {{'Order Submitted'|translate}}
                                                                        </div>

                                                                    </div>
                                                                    <div class="order-status-time-date">
                                                                        <div class="j-end" *ngIf="isPassedStatus('Accepted')">
                                                                            {{deliveryState.acceptedAt | iknDate:'tf'}}
                                                                        </div>
                                                                        <div class="card-track-data" *ngIf="isPassedStatus('Accepted')">
                                                                            {{deliveryState.acceptedAt | iknDate:'dmy'}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="margin-top-10"></div>
                                                        <div class="d-flex left-margin align-items-center font-bold">
                                                            <div class="main-track-circle" [class.inner-track-circle]="isPassedStatus('Preparing')">

                                                                <i *ngIf="isPassedStatus('Preparing')" class="fa fa-check"></i>
                                                            </div>
                                                            <div class="column w-100">
                                                                <div class="card-track w-100">
                                                                    <div class="card-track-status">
                                                                        <div class="img-icon">
                                                                            <img src="../../assets/images/2.png" *ngIf="! isPassedStatus('Preparing')">
                                                                            <img src="../../assets/images/2b.png" *ngIf="isPassedStatus('Preparing')">
                                                                        </div>
                                                                        <div class="j-start">
                                                                            {{'Processing'|translate}}
                                                                        </div>

                                                                    </div>

                                                                    <div class="order-status-time-date">
                                                                        <div class="j-end" *ngIf="deliveryState.order.processedAt">
                                                                            {{deliveryState.order.processedAt | iknDate:'tf'}}
                                                                        </div>
                                                                        <div class="card-track-data" *ngIf="deliveryState.order.processedAt">
                                                                            {{deliveryState.order.processedAt| iknDate:'dmy'}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="margin-top-10"></div>
                                                        <div class="d-flex left-margin  align-items-center font-bold">
                                                            <div class="main-track-circle" [class.inner-track-circle]="isPassedStatus('Prepared')">

                                                                <i *ngIf="isPassedStatus('Prepared')" class="fa fa-check"></i>
                                                            </div>
                                                            <div class="column w-100">
                                                                <div class="card-track w-100">
                                                                    <div class="card-track-status">
                                                                        <div class="img-icon">
                                                                            <img src="../../assets/images/3.png" *ngIf="! isPassedStatus('Prepared')">
                                                                            <img src="../../assets/images/3b.png" *ngIf="isPassedStatus('Prepared')">
                                                                        </div>
                                                                        <div class="j-start">
                                                                            {{'Ready to pickup'|translate}}
                                                                        </div>

                                                                    </div>
                                                                    <div class="order-status-time-date">

                                                                        <div class="j-end" *ngIf="deliveryState.order.processingEndedAt">
                                                                            {{deliveryState.order.processingEndedAt | iknDate:'tf'}}
                                                                        </div>
                                                                        <div class="card-track-data" *ngIf="deliveryState.order.processingEndedAt">
                                                                            {{deliveryState.order.processingEndedAt| iknDate:'dmy'}}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="margin-top-10" *ngIf="deliveryState.order.serviceType == 'delivery'">
                                                            <div class="d-flex left-margin align-items-center font-bold">
                                                                <div class="main-track-circle" [class.inner-track-circle]="isPassedStatus('PickedUp')">
                                                                    <i *ngIf="isPassedStatus('PickedUp')" class="fa fa-check"></i>
                                                                </div>
                                                                <div class="column w-100" *ngIf="deliveryState.order.serviceType == 'delivery'">
                                                                    <div class="card-track w-100">
                                                                        <div class="card-track-status">
                                                                            <div class="img-icon">
                                                                                <img src="../../assets/images/1.png" *ngIf="! isPassedStatus('PickedUp')">
                                                                                <img src="../../assets/images/1.png" *ngIf="isPassedStatus('PickedUp')">
                                                                            </div>
                                                                            <div class="j-start">
                                                                                {{'On the way' | translate}}
                                                                            </div>

                                                                        </div>
                                                                        <div class="order-status-time-date">
                                                                            <div class="j-end" *ngIf="deliveryState.pickedupAt">
                                                                                {{deliveryState.pickedupAt | iknDate:'tf'}}
                                                                            </div>
                                                                        </div>
                                                                        <div class="card-driver" *ngIf="isPassedStatus('PickedUp')">
                                                                            <div class="card-driver-inner">
                                                                                <div>
                                                                                    <span class="driver-name f-w-b">{{'Driver Name' | translate}}</span><span class="driver-name f-w-b">: </span><span class="driver-name  f-w-b">{{deliveryState.order?.driverName | iknLocalized}}</span>
                                                                                </div>
                                                                                <div>
                                                                                    <span class="driver-name f-w-b">{{'Contact' | translate}}</span><span class="driver-name f-w-b">: </span>
                                                                                    <span>
                                                                                        <span class="f-w-b contact-border-green"
                                                                                              *ngIf="deliveryState.order?.driverPhone != null">
                                                                                            <a [href]="'tel://' + deliveryState.order?.driverPhone">{{deliveryState.order?.driverPhone}}</a>
                                                                                        </span>
                                                                                    </span>
                                                                                </div>
                                                                                <div class="card-driver-inner j-start" (click)="mapClick()"
                                                                                *ngIf="!isPassedStatus('PendingPaymentFromDriver')">
                                                                               <div class="no-font">
                                                                                   <!-- <img src="../../assets/images/markers/map-marker.png" alt="track"
                                                                                        class="map-marker"> -->
                                                                               </div>
                                                                               <div class="p-b-5">
                                                                                   <a class="track-map f-w-b"><i class="fa fa-map-marker" aria-hidden="true"></i>{{'Track on Map' | translate}}</a>
                                                                               </div>
                                                                           </div>
                                                                            </div>
                                                                           
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="margin-top-10"></div>
                                                        <div class="d-flex  left-margin align-items-center font-bold">
                                                            <div class="main-track-circle" [class.inner-track-circle]="isPassedStatus('PendingPaymentFromDriver')">

                                                                <i *ngIf="isPassedStatus('PendingPaymentFromDriver')" class="fa fa-check"></i>
                                                            </div>
                                                            <div class="column w-100">
                                                                <div class="card-track w-100">
                                                                    <div class="card-track-status">
                                                                        <div class="img-icon">
                                                                            <img src="../../assets/images/4.png" *ngIf="! isPassedStatus('PendingPaymentFromDriver')">
                                                                            <img src="../../assets/images/4.png" *ngIf="isPassedStatus('PendingPaymentFromDriver')">

                                                                        </div>
                                                                        <div class="j-start">
                                                                            {{(deliveryState.order.serviceType == 'delivery' ? 'Delivered' : 'PickedUp') | translate}}
                                                                        </div>

                                                                    </div>
                                                                    <div class="order-status-time-date">
                                                                        <div class="j-end"
                                                                             *ngIf="deliveryState.deliveredAt || deliveryState.completedAt">
                                                                            {{ (deliveryState.deliveredAt || deliveryState.completedAt) | iknDate:'tf'}}
                                                                        </div>
                                                                        <div class="card-track-data" *ngIf="deliveryState.deliveredAt">
                                                                            {{deliveryState.deliveredAt| iknDate:'dmy'}}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="margin-top-10"></div>
                                                    </div>
                                                </div>
                                                <div class="invalid-feedback text-center font-bold" *ngIf="successRes && isCanceled">
                                                    <h2 class="font-weight-500">{{cancelMessage | translate}}</h2>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>

                            <div class="col respons-col" *ngIf="searchByOrderNum">
                                <div class="m-t-20"></div>
                                <div class="order-details margin-10">
                                    <table style="width:100%" class="">
                                        <tr>
                                            <td *ngIf="isPassedStatus('Accepted')" class="td-align-right pt-2">{{'Order Date' | translate}} :</td>
                                            <td *ngIf="isPassedStatus('Accepted')" class=" pt-2">   {{deliveryState.acceptedAt | iknDate:'dmy'}}</td>
                                            <td *ngIf="isPassedStatus('Accepted')" class="td-align-right pt-2">  {{'Order Time' | translate}} : </td>
                                            <td *ngIf="isPassedStatus('Accepted')" class=" pt-2">  {{deliveryState.acceptedAt | iknDate:'tf'}}</td>
                                        </tr>

                                        <tr>
                                            <td *ngIf="isPassedStatus('Accepted')" class="td-align-right pt-2">{{'Pickup Date' | translate}} :</td>
                                            <td *ngIf="isPassedStatus('Accepted')" class=" pt-2"> {{(order?.pickupTime || order?.expectedDeliveryTime) |iknDate:'dmy'}}</td>
                                            <td *ngIf="isPassedStatus('Accepted')" class="td-align-right pt-2">  {{'Pickup Time' | translate}} : </td>
                                            <td *ngIf="isPassedStatus('Accepted')" class=" pt-2">
                                                {{(order?.pickupTime || order?.expectedDeliveryTime) |iknDate:'tf'}}
                                                <span *ngIf="order?.pickupTimeTo || order?.expectedDeliveryTimeTo">
                                                    {{'to' | translate}}
                                                    {{(order?.pickupTimeTo || order?.expectedDeliveryTimeTo) |iknDate:'tf'}}
                                                </span>
                                            </td>
                                        </tr>

                                        <tr>

                                            <td colspan="4" class="order-detail-btn"><a [routerLink]="['/order-details', deliveryState.order.id]">{{'Order Details'| translate}}</a></td>

                                        </tr>

                                    </table>


                                </div>
                                <div class="m-t-20"></div>
                                <div *ngIf="successRes && !isCanceled" class=" tracking-line">

                                    <div style="clear: both;"></div>
                                    <div class="margin-top-10"></div>
                                    <div class="d-flex left-margin align-items-center font-bold">
                                        <div class="main-track-circle" [class.inner-track-circle]="isPassedStatus('Accepted')">
                                            <i *ngIf="isPassedStatus('Accepted')" class="fa fa-check"></i>
                                        </div>
                                        <div class="column w-100">
                                            <div class="card-track w-100">
                                                <div class="card-track-status">
                                                    <div class="img-icon">
                                                        <img src="../../assets/images/1.png" *ngIf="!isPassedStatus('Accepted')">
                                                        <img src="../../assets/images/1b.png" class="" *ngIf="isPassedStatus('Accepted')">
                                                    </div>
                                                    <div class="j-start">

                                                        {{'Order Submitted'|translate}}
                                                    </div>


                                                </div>
                                                <div class="order-status-time-date">
                                                    <div class="j-end" *ngIf="isPassedStatus('Accepted')">
                                                        {{deliveryState.acceptedAt | iknDate:'tf'}}
                                                    </div>
                                                    <div class="card-track-data" *ngIf="isPassedStatus('Accepted')">
                                                        {{deliveryState.acceptedAt | iknDate:'dmy'}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="margin-top-10"></div>
                                    <div class="d-flex  left-margin align-items-center font-bold">
                                        <div class="main-track-circle" [class.inner-track-circle]="isPassedStatus('Preparing')">

                                            <!-- <div class="inner-track-circle" *ngIf="isPassedStatus('Preparing')">
                                                <div class="solid-track-circle bg-primary">

                                                </div>
                                            </div> -->
                                            <i *ngIf="isPassedStatus('Preparing')" class="fa fa-check"></i>
                                        </div>
                                        <div class="column w-100">
                                            <div class="card-track w-100">
                                                <div class="card-track-status">
                                                    <div class="img-icon">

                                                        <img src="../../assets/images/2.png" *ngIf="!isPassedStatus('Preparing')">
                                                        <img src="../../assets/images/2b.png" *ngIf="isPassedStatus('Preparing')">
                                                    </div>
                                                    <div class="j-start">
                                                        {{'Processing'|translate}}
                                                    </div>
                                                </div>
                                                <div class="order-status-time-date">
                                                    <div class="j-end" *ngIf="deliveryState.order.processedAt">
                                                        {{deliveryState.order.processedAt | iknDate:'tf'}}
                                                    </div>

                                                    <div class="card-track-data" *ngIf="deliveryState.order.processedAt">
                                                        {{deliveryState.order.processedAt| iknDate:'dmy'}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="margin-top-10"></div>
                                    <div class="d-flex  left-margin align-items-center font-bold">
                                        <div class="main-track-circle" [class.inner-track-circle]="isPassedStatus('Prepared')">

                                            <i *ngIf="isPassedStatus('Prepared')" class="fa fa-check"></i>
                                        </div>
                                        <div class="column w-100">
                                            <div class="card-track w-100">
                                                <div class="card-track-status">
                                                    <div class="img-icon">
                                                        <img src="../../assets/images/3.png" *ngIf="!isPassedStatus('Prepared')">
                                                        <img src="../../assets/images/3b.png" *ngIf="isPassedStatus('Prepared')">

                                                    </div>
                                                    <div class="j-start">
                                                        {{'Ready to pickup'|translate}}
                                                    </div>

                                                </div>
                                                <div class="order-status-time-date">
                                                    <div class="j-end" *ngIf="deliveryState.order.processingEndedAt">
                                                        {{deliveryState.order.processingEndedAt | iknDate:'tf'}}
                                                    </div>
                                                    <div class="card-track-data" *ngIf="deliveryState.order.processingEndedAt">
                                                        {{deliveryState.order.processingEndedAt| iknDate:'dmy'}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="margin-top-10" *ngIf="deliveryState.order.serviceType == 'delivery'">
                                        <div class="d-flex  left-margin align-items-center font-bold row align-items-center font-bold">
                                            <div class="main-track-circle" [class.inner-track-circle]="isPassedStatus('PickedUp')">
                                                <!-- <div class="inner-track-circle" *ngIf="isPassedStatus('PickedUp')">
                                                    <div class="solid-track-circle bg-primary">

                                                    </div>
                                                </div> -->
                                                <i *ngIf="isPassedStatus('PickedUp')" class="fa fa-check"></i>
                                            </div>
                                            <div class="column w-100" *ngIf="deliveryState.order.serviceType == 'delivery'">
                                                <div class="card-track w-100">
                                                    <div class="card-track-status">
                                                        <div class="img-icon">
                                                            <img src="../../assets/images/3.png" *ngIf="! isPassedStatus('PickedUp')">
                                                            <img src="../../assets/images/3b.png" *ngIf="isPassedStatus('PickedUp')">
                                                        </div>
                                                        <div class="j-start">
                                                            {{'On the way' | translate}}
                                                        </div>

                                                    </div>
                                                    <div class="order-status-time-date">
                                                        <div class="j-end" *ngIf="deliveryState.pickedupAt">
                                                            {{deliveryState.pickedupAt | iknDate:'tf'}}
                                                        </div>
                                                    </div>

                                                    <div class="card-driver" *ngIf="isPassedStatus('PickedUp')">
                                                        <div class="card-driver-inner">
                                                            <div>
                                                                <span class="driver-name f-w-b">{{'Driver Name' | translate}}</span><span class="driver-name f-w-b">: </span><span class=" f-w-b">{{deliveryState.order?.driverName | iknLocalized}}</span>
                                                            </div>
                                                            <div>
                                                                <span class="driver-name f-w-b">{{'Contact' | translate}}</span><span class="driver-name f-w-b">: </span><div>
                                                                    <span class="driver-color f-w-b contact-border-green"
                                                                          *ngIf="deliveryState.order?.driverPhone != null">
                                                                        <a [href]="'tel://' + deliveryState.order?.driverPhone">{{deliveryState.order?.driverPhone}}</a>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card-driver-inner t-center" (click)="mapClick()"
                                                             *ngIf="!isPassedStatus('PendingPaymentFromDriver')">
                                                            <div class="no-font">
                                                                <img src="../../assets/images/markers/map-marker.png" alt="track"
                                                                     class="map-marker">
                                                            </div>
                                                            <div class="p-b-5">
                                                                <span class="track-map f-w-b">{{'Track on Map' | translate}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="margin-top-10"></div>
                                    <div class="d-flex  left-margin align-items-center font-bold">
                                        <div class="main-track-circle" [class.inner-track-circle]="isPassedStatus('PendingPaymentFromDriver')">
                                            <i *ngIf="isPassedStatus('PendingPaymentFromDriver')" class="fa fa-check"></i>
                                        </div>
                                        <div class="column w-100">
                                            <div class="card-track w-100">
                                                <div class="card-track-status">
                                                    <div class="img-icon">
                                                        <img src="../../assets/images/4.png" *ngIf="! isPassedStatus('PendingPaymentFromDriver')">
                                                        <img src="../../assets/images/4b.png" *ngIf="isPassedStatus('PendingPaymentFromDriver')">

                                                    </div>
                                                    <div class="j-start">

                                                        {{(deliveryState.order.serviceType == 'delivery' ? 'Delivered' : 'PickedUp') | translate}}
                                                    </div>

                                                </div>
                                                <div class="order-status-time-date">
                                                    <div class="j-end"
                                                         *ngIf="deliveryState.deliveredAt || deliveryState.completedAt">
                                                        {{ (deliveryState.deliveredAt || deliveryState.completedAt) | iknDate:'tf'}}
                                                    </div>
                                                    <div class="card-track-data" *ngIf="deliveryState.deliveredAt">
                                                        {{deliveryState.deliveredAt| iknDate:'dmy'}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="margin-top-10"></div>
                                </div>
                                <div class="invalid-feedback text-center font-bold" *ngIf="successRes && isCanceled">
                                    <h2 class="font-weight-500">{{cancelMessage | translate}}</h2>
                                </div>
                            </div>
                        </div>



                        <div class="invalid-feedback text-center font-bold" *ngIf="submitted && notFoundRes">
                            <h2 class="font-weight-500">{{'Order not found' | translate}}</h2>
                        </div>
                    </div>
                </div>

            </div>
            <div *ngIf="searchByOrderNum">
                <div class="form-group btn-container text-center">
                    <a target="blank" class="btn btn-continue d-inline text-center" type="button"
                       [href]="'https://www.google.com/maps/search/?api=1&query=' + deliveryState.order.truckLocation?.location.lat + ',' + deliveryState.order.truckLocation?.location.lng">
                        {{'Get Directions' | translate}}

                        <i class="fa fa-map-marker" style="color: white!important"></i>
                    </a>
                </div>
            </div>
        </div>

        <div>

            <app-footer></app-footer>
        </div>
    </form>
</div>
<app-order-success-popup (trackOrder)=" getOrderData($event);"></app-order-success-popup>