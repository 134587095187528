import { Pipe, PipeTransform, LOCALE_ID, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'iknTime',
  pure:false,
})
export class IknTimePipe implements PipeTransform {
 
  constructor(@Inject(TranslateService) private srvTrans:TranslateService) {
    
  }
  transform(date: any): any {
    let locale = this.srvTrans.currentLang == 'ar' ? 'ar' : 'en-US';
    let datePipe = new DatePipe(locale);
    if(!date)
      return;
      let parts = _.split(date, ':');
      let hours = +parts[0];
      let minutes = +parts[1] || 0;
      let d = new Date();
      d.setHours(hours);
      d.setMinutes(minutes);
      return datePipe.transform(d, 'h:mm a');
  }

}
