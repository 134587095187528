import { Injectable }             from '@angular/core';
import { CanActivate, Router,
ActivatedRouteSnapshot,
RouterStateSnapshot }    from '@angular/router';
import { CartService } from './cart.service';
import { Cart } from './cart';
import {environment} from 'src/environments/environment';

@Injectable()
export class AreaSelectionGuard implements CanActivate {
  cart:Cart
  env = environment;
  constructor(private router: Router, private _srvCart:CartService) {
    this.cart = _srvCart.getCart();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if(!this.env.display_home)
      return true;

    if(this.env.display_home){ 
        let isShipping = this.cart.orderInfo && this.cart.orderInfo.pickupAddress && this.cart.orderInfo.pickupAddress.countryId && this.cart.orderInfo.pickupAddress.countryId != environment.default_country; 
        if (this.cart && this.cart.orderInfo && this.cart.orderInfo.pickupAddress
           && ((this.cart.orderInfo.pickupAddress.areaId && !isShipping)
           || (isShipping && this.cart.orderInfo.pickupAddress.countryId 
            && (this.cart.orderInfo.pickupAddress.areaId || this.cart.orderInfo.pickupAddress.governateId)))){
          return true;
        }else {
            let redirectUrl = state.url;
            this.router.navigate(['/home', {redirect: redirectUrl}]);
            return false;
        }
    }
    else{
      let redirectUrl = state.url;
      if(redirectUrl == '/')  return true;
      else {
        this.router.navigate(['/']);
        return false;
      }
    }


  }
}
