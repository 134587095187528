<ngx-smart-modal #mSelectSubstitution identifier="mExtraCatAlert" [dismissable]="false">
    <div class="extra-category font-bold">
        <button class="dismiss-btn color-primary" (click)="onOk()">
            {{'Agree'|translate}}
        </button>
        <div class="margin-top-10"></div>
        <h1 class="font-extraBold">
        {{'Alert' | translate}}
        </h1>
        <div class="margin-top-10"></div>
        <p>{{'extra_charge_txt' | translate}} {{deliveryCharge * 1000}} {{'Fils' | translate}}
          <span *ngIf="deliveryTime > 0">{{'and' |translate}} {{deliveryTime}} {{'extra_time_txt' | translate}}</span>

        </p>

    </div>
</ngx-smart-modal>
