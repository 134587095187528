import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {
  
  constructor(private toaster: ToastrService,
    private translate: TranslateService) { }

  sweet_notify(opt) {
    Swal.fire(opt.title, opt.text, opt.type );
  }

  sweet_error(err, title?: string, fn?: any) {
    err = this.extractErrorObject(err);
    if (typeof title === "function") {
      fn = title;
      title = undefined;
    }

    this.sweet_notify({
      title: this.translate.instant(title || "Error"),
      text: this.translate.instant(err.msg || "An error has occured"),
      type: "warning",
    });
  }

  sweet_warning(msg, title?: string) {
    this.sweet_notify({
      title: this.translate.instant(title || "Warning"),
      text: this.translate.instant(msg),
      type: "warning",
    });
  }

  sweet_info(msg, title?: string) {
    this.sweet_notify({
      title: this.translate.instant("Info"),
      text: this.translate.instant(msg),
      type: "info",
    });
  }

  sweet_confirm(msg, title, fnSucess, fnCancel?) {
    Swal.fire({
      title: this.translate.instant(title || "Are You Sure?"),
      text: this.translate.instant(msg),
      icon: "warning",
      confirmButtonColor:"rgb(106, 162, 119)",//"#dc3545",
      cancelButtonColor: 'rgb(220, 97, 108)',// '#28a745',
      focusCancel:false,
      focusConfirm:false,
      reverseButtons: this.translate.currentLang == 'ar',
      allowEscapeKey:true,
      allowOutsideClick:true,
      confirmButtonText: this.translate.instant("Yes, do it!"),
      cancelButtonText: this.translate.instant("No, cancel!"),
      showCancelButton:true,
      showConfirmButton:true,
    }).then((result) => {
      if (result.value) {
        fnSucess();
      } else if (result.dismiss && fnCancel) {
        fnCancel();
      }
    });
  }

  sweet_success(msg, title?: string, fnSucess?, fnCancel?) {
    this.sweet_notify({
      title: this.translate.instant(title || "Success"),
      text: this.translate.instant(msg || "Success"),
      type: "success",
    });
  }

  extractErrorObject(error: any){
    var _title;
    var msg = '';
    var code = '';

    if (error && error.error && error.error.errorDetail && error.error.errorDetail.errors instanceof Array) {
      let msg = '';
      _.each(error.error.errorDetail.errors, err => {
        if (err.code == "5311")
          return;
        msg += `${this.extractErrorObject(err).msg}\r\n`;
      });
      return {
        title: this.translate.instant('Multiple Errors'),
        msg: msg,
      }
    } else {
      if (error) {
        error = error.error || error;
        if (error.code && error.code == '3104') {
          return this.extractErrorObject(error.errorDetail.errors);
        }
        if (error.modelState) {
          msg = '';
          _.forIn(error.modelState, (value, key) => msg += this.translate.instant(value) + '\r\n');
        }
        else if (error.errorDetail) {
          if (error.errorDetail.errorMessage)
            msg = this.translate.instant(error.errorDetail.errorMessage);
        }
        else if (error.errorMessage)
          msg = this.translate.instant(error.errorMessage);
        else if (error.ExceptionMessage)
          msg = this.translate.instant(error.ExceptionMessage);
        else if (error.message)
          msg = this.translate.instant(error.message);
        else if (error.Message)
          msg = this.translate.instant(error.Message);
        else
          msg = this.translate.instant(error);
        code = error.errorCode || error.code;
      }
      if (!msg || msg == '')
        msg = this.translate.instant('Error');

      // extract msg by code
      if (code) {
        code = "err_" + code;
        let tmsg = this.translate.instant(code);
        if (tmsg !== code) {
          msg = code
        }
      }

      msg = msg;
    }
    return {
      title: _title && this.translate.instant(_title),
      msg: this.translate.instant(msg)
    };

  }
}
