<form [formGroup]="submitForm" (ngSubmit)="resetPassword()">
    <div class="page-heading">
      <div class="heading-l">
        <div class="title"><a class="back color-primary" (click)="utils.goBack()"><i class="fa fa-chevron-left"></i></a></div>
      </div>
      <div class="heading-l heading-c">
        <div class="title font-extraBold">{{'Reset Password'| translate}}</div>
      </div>
      <div class="heading-r"></div>
    </div>
    <div iknLoader [isActive]='isLoading' class="loader-container"></div>
    <div class="page-content">
      <div class="checkout-block">
        <div class="form-group" *ngIf="!isLoading">
          <div class="form-group-body font-extraBold text-right">
            <div class="input-group">
                <div class="m-t-20"></div>
                <div class="input-wrapper text-center" *ngIf="logo">
                    <img [src]="logo" alt=""/>
                </div>
                <div class="input-wrapper">
                    <label for="password" class="font-bold lbl-txt">{{'New Password' | translate}} <span class="required-color">*</span></label>
                    <input [type]="showPass ? 'text' : 'password'" placeholder="{{'Password' | translate}}"
                    formControlName="password" [(ngModel)]="data.newPassword" required class="p-r-65">
                    <a class="show-btn color-primary" (click)="showPassword()" *ngIf="data.newPassword">
                        {{showPass ? ('Hide'|translate) : ('Show'|translate)}}
                    </a>
                    <div *ngIf="(submitted || f.password.touched) && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">{{'Password is required'|translate}}</div>
                    </div>
                </div>

                <div class="input-wrapper">
                    <label for="confirmPassword" class="font-bold lbl-txt">{{'Confirm Password' | translate}} <span class="required-color">*</span></label>
                    <input type="password" placeholder="{{'Password' | translate}}" pattern="{{data.newPassword}}"
                    formControlName="confirmPassword" [(ngModel)]="confirmPassword" required id="confirmPassword">
                    <div *ngIf="(submitted || f.confirmPassword.touched) && f.confirmPassword.errors" class="invalid-feedback">
                    <div *ngIf="f.confirmPassword.errors.required">{{'Confirm Password is required'|translate}}</div>
                    <div *ngIf="f.confirmPassword.errors.pattern">{{'Password & Confirm Password do not match.'|translate}}</div>
                    </div>
                </div>

                <div class="login-btn page-btn font-bold" (click)="resetPassword()">
                    <a class="btn btn-primary bg-primary btn-cart">{{'Reset' | translate}}</a>
                </div>
            </div>
          </div>
        </div>
  
      </div>
    </div>
   
  </form> 