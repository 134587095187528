import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../utils.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../api.service';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { NotifyService } from '../notify.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  submitForm: FormGroup;
  submitted = false;
  isLoading:boolean = false;
  email:string= '';
  logo:string= '';

  constructor(public utils:UtilsService,
    private router:Router,
    private api:ApiService, 
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private toaster:ToastrService,
    private notify: NotifyService) {}

  ngOnInit() {
    this.utils.fbTrack('ForgetPassword');
    //$('.page-side').removeClass('sm-hidden');
    this.submitForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.api.checkIfAuthenticated().subscribe(res => {
      if (res && this.api.getAuthUser().userId != -1)
        this.router.navigate(['/home']);
    });

    this.api.viewSBOptionsDetails().subscribe(op => {
      this.logo = op.simpleBrokerOptions && op.simpleBrokerOptions.logo || environment.logo;;
    });
  }

   // convenience getter for easy access to form fields
   get f() { return this.submitForm.controls; }

   forgetPassword(){
    this.submitted = true;
     // stop the process here if form is invalid
    if (this.submitForm.invalid)
    return;

    this.isLoading = true;

    this.api.forgetPassword(this.email).subscribe(res => {
      this.isLoading = false;
      this.router.navigate(['/login']);
      this.toaster.success(this.translate.instant('An Email with reset password instruction is on its way'));
    }, err => {
      this.isLoading = false;
      this.notify.sweet_warning('Email not found'); 
    });
  }

}
