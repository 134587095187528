import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { UtilsService } from '../utils.service';
import { NotifyService } from '../notify.service';
import { CartService } from '../cart.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Cart } from '../cart';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash'

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.css']
})
export class AddressesComponent implements OnInit {

  lstAddresses: any;
  isLoading: boolean = false;
  nextUrl: string= '';
  selectedAddressId?: number;
  cart:Cart;
  deleteAddress: boolean = false;
  type:string='list';
  constructor(public utils: UtilsService, private api: ApiService, private notify: NotifyService, private srvCart: CartService
    ,private router:Router, private route: ActivatedRoute
    ,private translate: TranslateService, private toaster: ToastrService) {
      this.cart = this.srvCart.getCart();
    }

  ngOnInit() {
    this.utils.fbTrack('Addresses');
    this.route.data.subscribe(d=>{
      this.type = d['type'] || 'list';
    });
    this.selectedAddressId = this.cart.orderInfo.pickupAddress.selectedAddressId;
    this.route.params.subscribe(params => {
      this.nextUrl = params['returnTo'];

   });

    if(!this.api.isLoggedIn)
      this.router.navigate(['/login', {returnTo:this.nextUrl || 'my-addresses'}], {skipLocationChange:false,replaceUrl:true});

    this.loadAddresses();
  }

  loadAddresses(){
    this.isLoading = true;
    this.api.getAddresses().subscribe(res => {
      this.lstAddresses = res;
      console.log(res);
      // if(this.nextUrl && !this.selectedAddressId && this.lstAddresses) // select defualt one
      // this.selectedAddressId = res[0].id;
      if(this.selectedAddressId){
        let found = _.find(res, address => address.id == this.selectedAddressId);
        if(found ){
          this.selectedAddressId = undefined;
          this.cart.orderInfo.pickupAddress.selectedAddressId = undefined;
        }
      }
      this.isLoading = false;
    });
  }

  removeAddress(address){
    this.deleteAddress = true;
    let that = this;
    this.notify.sweet_confirm('Address will be deleted', undefined, function () {
      that.isLoading = true;
      that.deleteAddress = false;
      that.api.deleteAddress(address.id).subscribe(function () {
        that.selectedAddressId = that.nextUrl && that.selectedAddressId == address.id ? undefined : that.selectedAddressId;
        that.notify.sweet_success('Address is deleted successfully');
        that.loadAddresses();
      }, function (error) {
        that.isLoading = false;
        that.notify.sweet_error(error);
      });
    }, that.deleteAddress = false);
  }

  selectAddress(address){
    if(this.type !== 'select')
      return;
    address.selectedAddressId = address.id;
    this.srvCart.setAddress(address.address);
    this.router.navigate(['/checkout']);

  }

  checkout(){
    if(this.selectedAddressId)
      this.router.navigate(['/checkout']);
    else
      this.toaster.error(this.translate.instant('You must select address first'));
  }

}
