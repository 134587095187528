<app-top-header class='top-bar-container' bgStyle="solid" screenTitle="Refine Location"></app-top-header>
<div *ngIf="!(pickupAddress.location.lat && pickupAddress.location.lng && areaColor != 'red')" class="alert" role="alert">
    <div class="d-flex justify-content-between mb-2">
        <label class="m-0 f-boldes t-large"> {{'Outside selected area' | translate}}</label>
        <app-area [(areaId)]='selected.id'
                  [(areaName)]='selected.name'
                  [(areaObj)]='selected'
                  [saveToCart]='false'
                  (areaChanged)='onAreaSelected($event)'></app-area>
    </div>
</div>
<div class="w-100 position-relative">

    <div style="height: calc(100vh - 10rem);" class="position-relative border rounded shadow">
        <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="mapZoom || 17"
                 [streetViewControl]="false"
                 (centerChange)="onCenterChanged($event)"
                 (idle)="onMapIdle()"
                 style="height: 100%;width:100%" #agMap>
            <agm-polygon [paths]="boundaries"
                         [fillColor]="areaColor"
                         [strokeColor]="areaColor"
                         [fillOpacity]="0.1"
                         [clickable]="false">
            </agm-polygon>
        </agm-map>
    </div>

    <div class="position-absolute" style="top:calc(50% + 23px); left:calc(50% - 20px)">
        <img src="../../assets/images/markers/map-marker2.svg" style="width:40px;height:40px;object-fit: fill;">
    </div>
    <div class='position-absolute px-3' style='bottom:120px;right:0'>
        <div class='p-1 cursor-pointer bg-white ' (click)='setCurrentLocation()'>
            <i><i class="fad fa-location fa-2x color-primary"></i></i>
        </div>
    </div>
</div>

<div class="page-btn f-boldes t-large" *ngIf="pickupAddress.location.lat && pickupAddress.location.lng && areaColor != 'red'"
     [@slideInUpOnEnter]>
    <a class="btn btn-primary bg-primary btn-cart" (click)="save()">
        <div>{{'Set Location' | translate}}</div>
        <div *ngIf="!isGeocoding" class="t-medium text-truncate">{{pickupAddress.addressLine1}}</div>
        <div iknLoader [isActive]='isGeocoding' class="loader-container inverse"></div>

    </a>
</div>
<!--<div *ngIf="areaColor == 'red'" class="alert alert-danger t-medium f-bold position-absolute" style="top:0;left:0;right:0">
    {{'Outside selected area' | translate}} <a class="f-boldest cursor-pointer" routerLink="['/service']">{{'Click to choose another area' | translate}}</a>
</div>-->

<div style="margin-top:25px"></div>

<app-footer></app-footer>