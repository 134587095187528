<app-top-header class="top-bar-container" bgStyle="solid" screenTitle=' '></app-top-header>
<div class="page-content cursor-pointer" *ngIf="truck">
    <div class="menu-block color-primary">
        <div class="d-flex flex-column justify-content-center align-items-center">
            <img class="logo-big" [src]="truck?.logoPic">
            <h1>{{truck?.name | iknLocalized}}</h1>
            <div class="text-center">
                {{truck?.notes | iknLocalized}}
            </div>
        </div>
        <div class="d-flex color-primary mt-3" (click)='expandedSections.workingHours = !expandedSections.workingHours'>
            <h2 class="m-0 flex-grow-1">
                <span><i class="fa fa-clock-o"></i></span>
                <span class='mx-2'>{{'Working Hours' | translate}}</span>
            </h2>
            <div>
                <span><i class="far"
                        [ngClass]="{'fa-angle-up':expandedSections.workingHours,'fa-angle-down':!expandedSections.workingHours}"></i></span>
            </div>
        </div>
        <hr class="m-0 p-0" />
        <div class="d-flex flex-column" *ngIf='expandedSections.workingHours'>
            <div class="d-flex mt-2" *ngFor="let time of truck?.operatingHours">
                <div class="font-bold">{{time.day | translate}}: </div>
                <div class="mx-1">
                    <span *ngIf="time.state != '24 hours'">
                        {{time.operatingFrom | iknTime}} {{time.operatingTo | iknTime}}
                    </span>
                    <span *ngIf="time.state == '24 hours'">
                        {{'All Day' | translate}}
                    </span>
                </div>
            </div>
        </div>
        <div *ngIf='branches && branches.length'>
            <div class="mt-4 d-flex justify-content-between align-items-center  color-primary "
                (click)='expandedSections.branches = !expandedSections.branches'>
                <h2 class="m-0 flex-gorw-1 ">
                    <span><i class="fa fa-map-marker"></i></span>
                    <span class='mx-2'>{{'Branches locations' | translate}}</span>
                </h2>
                <div>
                    <span><i class="far"
                            [ngClass]="{'fa-angle-up':expandedSections.branches,'fa-angle-down':!expandedSections.branches}"></i></span>
                </div>
            </div>
            <hr class="m-0 p-0" />
            <ng-container *ngIf='expandedSections.branches'>
                <div class="d-flex flex-column mt-2" *ngFor="let branch of branches">
                    <div class='d-flex justify-content-between align-items-center'>
                        <div class="f-boldest">{{branch.name | iknLocalized}} </div>
                        <div class="cursor-pointer color-primary">{{branch.status | translate}} </div>
                    </div>
                    <div class="f-bold t-normal">{{branch.address | iknLocalized}}</div>
                    <div class="mx-1 mt-4 w-100"
                        *ngIf='branch.location?.location?.lat && branch.location?.location?.lng'>
                        <agm-map [latitude]="branch.location.location.lat" [longitude]="branch.location.location.lng"
                            [zoom]='15' style="height: 150px;width:100%">
                            <agm-marker [latitude]="branch.location.location.lat"
                                [longitude]="branch.location.location.lng" [label]='({ text: branch.name.ar })'
                                class='marker-label'>
                            </agm-marker>
                        </agm-map>
                    </div>
                    <div class='p-4 d-flex justify-content-center align-items-center'>
                        <a class='border cursor-pointer px-4 py-2  f-bolder t-medium'
                            [href]='"tel://" + branch?.location?.contactPhone'>
                            {{'Call Branch' | translate}}
                        </a>
                        <div class='mx-2' *ngIf='branch.location?.location?.lat && branch.location?.location?.lng'>
                        </div>
                        <a class='border cursor-pointer  px-4 py-2  f-bolder  t-medium'
                            *ngIf='branch?.location?.location?.lat && branch?.location?.location?.lng'
                            [href]="'https://www.google.com/maps/search/?api=1&query=' + branch?.location?.location?.lat + ',' + branch?.location?.location?.lng">
                            {{'Get Directions' | translate}}
                        </a>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="mt-4">
            <div class='d-flex justify-content-between align-items-center color-primary '
                (click)='expandedSections.addToHome = !expandedSections.addToHome'>
                <h2 class="m-0 flex-grow-1">
                    <img src="../../assets/images/Share.png" style='max-height: 1.5em;  object-fit:contain;' />
                    <span class='mx-2'>{{'Add To Home Screen' | translate}}</span>
                </h2>
                <div>
                    <span>
                        <i class=" far"
                            [ngClass]="{'fa-angle-up':expandedSections.addToHome,'fa-angle-down':!expandedSections.addToHome}"></i>
                    </span>
                </div>

            </div>
            <hr class="m-0 p-0" />

            <div class="mt-2" *ngIf='expandedSections.addToHome'>


                <div class='p-2'>
                    {{'This website has app functionality. Add it to your home screen to use it in fullscreen and while offline' | translate}}
                </div>
                <div class='border-bottom'></div>

                <div class='p-2 d-flex jsutify-content-center'>
                    <div class='px-2 flex-grow-1'> {{'1) Press the Share button' | translate}} <img
                            src='/assets/images/Share.png' class='mx-1' style='max-height: 1.5em;  object-fit:contain'>
                        {{"on the menu bar below" |translate}}</div>

                </div>
                <div class='p-2 d-flex jsutify-content-center'>
                    <div class='px-2 flex-grow-1'> {{"2) Press Add to Home Screen" |translate}} <img
                            src='/assets/images/AddToHome.png' class='mx-1'
                            style='max-height: 1.5em; object-fit:contain;vertical-align: middle!important;'></div>
                </div>
            </div>
        </div>
        <div class="mt-4  color-primary " *ngIf="showTerms" >
            <h2 class="m-0 w-100" routerLink='/terms'>
                {{'Terms And Conditions' | translate}}
            </h2>
        </div>
        <hr class="m-0 p-0" />
        <div class="mt-4">
            <div class='d-flex px-2 flex-wrap justify-content-center align-items-center'>
                <a class='mx-2 mb-4 p-2 rounded-circle border-ex d-flex flex-column justify-content-center align-items-center border-ex-black border-ex-thick'
                    target="_blank" *ngFor='let link of sb?.socialLinks | keyvalue' [href]='link?.value?.link'
                    style='width:2.7em;height: 2.7em;'>
                    <i [attr.class]='link?.value?.icon + " fa-1-5"'></i>
                </a>
            </div>
        </div>
    </div>