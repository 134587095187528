<!-- <app-top-header bgStyle="solid" screenTitle="Checkout"></app-top-header>
<div class="page-content px-4 my-4">
  <app-address-part #address></app-address-part>

</div>
<div class="page-btn font-bold" (click)="cmpAddress.saveAddress(pickupAddress) && confirmAddress()">
  <a class="btn btn-primary bg-primary btn-cart">{{'CONFIRM DELIVERY ADDRESS' | translate}}</a>
</div>
<app-footer></app-footer> -->

<app-top-header class='top-bar-container' bgStyle="solid" [screenTitle]="screenTitle"></app-top-header>
<div class="page-content px-4 my-4">
  <app-address-part #address [returnTo]='locationReturnTo'></app-address-part>
  
</div>
<div class="page-btn font-bold" (click)="confirmAddress()">
  <a class="btn btn-primary bg-primary btn-cart">
    <span [ngSwitch]="type">
      <span *ngSwitchCase="'add'">{{'ADD ADDRESS' | translate}}</span>
      <span *ngSwitchCase="'edit'">{{'SAVE CHANGES' | translate}}</span>
      <span *ngSwitchCase="'select'">{{'CONFIRM DELIVERY ADDRESS' | translate}}</span>
    </span>
  </a>
</div>
<app-footer></app-footer>
