<div class="ikn-modal">
<ngx-smart-modal #mSelectBranch identifier="mSelectBranch">
  <div class="comp-branch">
    <button class="dismiss-btn font-bold" (click)="modal.getModal('mSelectBranch').close()">
      <i class="fa fa-times"></i>
    </button>
    
    <h1 class="ikn-modal-header font-extraBold">
      {{'Choose Your Pickup Location' | translate}}
    </h1>

    <div class='col'>
      <!-- <div class="wrap font-extraBold">
        <div class="search">
            <button type="button" class="search_button">
                <i class="fa fa-map-marker"></i>
            </button>
            <input type="text" class="search_term" placeholder="{{'Search for branch' | translate}}" [(ngModel)]="searchText">
        </div>
      </div> -->

        <!-- <div class="input-wrapper ">
          <input type="text" class="" placeholder="" [(ngModel)]="searchText">
          <i class="im im-search"></i>
        </div> -->
      </div>
    <div class='branch-selector-container font-extraBold'>
      <div iknLoader [isActive]='isLoading' class="loader-container"></div>
      <div name="selected" *ngIf="!isLoading">
      <p class="branch text-right" *ngFor="let branch of branches | filter:searchText"
              (click)="onSave(branch)">
              {{branch.name | iknLocalized}}
            </p>
      </div>
    </div>
  </div>
</ngx-smart-modal>
</div>
<span (click)="selectBranch()">
  <!-- <span *ngIf="selectedBranch">{{selectedBranch.name | iknLocalized}}</span> -->
  <!-- <span *ngIf="!selectedBranch">{{ 'Select Branch' | translate }}</span>) -->
  <a *ngIf="notPickup && cart.$branch" class="branches-btn">{{cart.$branch.name | iknLocalized}} <i class="fa fa-chevron-down"></i></a>
  <span class="color-primary branch-label" *ngIf="!notPickup">{{ (branch ? 'Change Branch' : 'Select Branch') | translate }}</span>
</span>
