<div class="page-heading">
    <div class="heading-l">
      <div class="title"><a class="back color-primary" (click)="utils.goBack()"><i class="fa fa-chevron-left"></i></a></div>
    </div>
    <div class="heading-l heading-c">
        <div class="title font-extraBold">{{'About Us'| translate}}</div>
    </div>
    <div class="heading-r"></div>
</div>
<div class="page-content m-8">
    <!-- {{aboutUs}} -->
    <div [innerHTML]= "aboutUs|iknLocalized"></div>
</div>

