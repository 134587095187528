import { Component, Input } from '@angular/core';
import { CategoryView } from '../models';
import * as _ from 'lodash'
import { Router } from '@angular/router';
import { NgIfContext } from '@angular/common';
import { CartService } from '../cart.service';
import { Cart } from '../cart';
import { UtilsService } from '../utils.service';
@Component({
  selector: 'app-base-category-card',
  templateUrl: './base-category-big-card.component.html',
  styleUrls: ['./base-category-big-card.component.css']
})
export class BaseCategoryBigCardComponent {

  @Input('category') cat: CategoryView
  cart: Cart
  constructor(private router: Router, private srvCart: CartService, private utils: UtilsService) {
    this.cart = srvCart.getCart();
  }
  onCategoryClick(cat: any) {
    this.utils.CheckStateAndContinue(this.cart.$truck.lastKnowState, () => {
      if (cat.categoryInfo.id == -100) //catering
      {
        //this.srvCart.setServiceType('catering', 'catering');
        this.router.navigate(['catering-area', { returnTo: '/categories/' + cat.categoryInfo.id + '/items' }], {
        });
        return;
      }
      if (cat.subCategories && cat.subCategories.length) this.router.navigate(['/sub-category/' + cat.id]);
      else this.router.navigate(['/categories/' + cat.categoryInfo.id + '/items']);
    });
  }
}
