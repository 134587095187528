import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import { UtilsService } from '../utils.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2'
import { ActivatedRoute, Router } from '@angular/router';

import * as _ from 'lodash';
import { AgmMap } from '@agm/core';
import { param } from 'jquery';
import { OrderSuccessPopupComponent } from '../order-success-popup/order-success-popup.component';
@Component({
    selector: 'app-track-order',
    templateUrl: './track-order.component.html',
    styleUrls: ['./track-order.component.css', './track-order.component.scss']
})
export class TrackOrderComponent implements OnInit {
    @ViewChild('agMap') agMap: AgmMap;
    @ViewChild(OrderSuccessPopupComponent) orderSuccessSelector: OrderSuccessPopupComponent;
    deliveryStateList: any;
    selectedOrder: any;
    searchByOrderNum: boolean = false;
    order: any;
    constructor(private route: ActivatedRoute, private mapRouter: Router, private formBuilder: FormBuilder, private api: ApiService, public utils: UtilsService, private translate: TranslateService) { }
    submitForm: FormGroup;
    submitted = false;
    isCanceled = false;
    cancelMessage = '';
    isLoading: boolean = false;
    orderNo: "";
    orderId: "";
    successRes: boolean = false;
    notFoundRes: boolean = false;
    loadImg = '../../assets/images/loading_circle.png';
    checkedImg = '../../assets/images/check_box_circle.png';
    disabledImg = '../../assets/images/empty_circle.png';
    res: any = {};
    deliveryState: any = { order: {} };
    // convenience getter for easy access to form fields
    get f() { return this.submitForm.controls; }

    ngOnInit() {
        this.searchByOrderNum = false;
        this.submitForm = this.formBuilder.group({
            orderNo: ['', Validators.required],
        });
        this.route.queryParams
            .subscribe(params => {
                this.orderNo = params.orderNo || "";
                this.orderId = params.id || "";
                let type = params.ot || "";
                if (this.orderId)
                    setTimeout(() => this.orderSuccessSelector.showOrderSuccessPopup(() => { this.trackOrder(); }, this.orderId, type));
                else
                    this.trackOrder();
            });
    }

    submitTrackOrder() {
        this.submitted = true;

        // stop the process here if form is invalid
        if (this.submitForm.invalid)
            return;

        this.trackOrder();
    }
    assignvalue(o: any) {
        this.searchByOrderNum = false;
        this.selectedOrder = o;
        this.deliveryState = this.selectedOrder;

    }
    trackOrder() {
        this.successRes = false;
        this.notFoundRes = false;
        this.searchByOrderNum = false;
        if (this.isLoading)
            return; //already loading something
        this.isLoading = true;
        this.api.getDeliveryStatus(this.orderNo).subscribe(res => {
            this.isLoading = false;
            if (res.deliveryState) {
                this.deliveryStateList = null;
                this.searchByOrderNum = true;
                this.deliveryState = res.deliveryState;
            }
            else {
                this.deliveryStateList = res.deliveryStateList;
                if (this.selectedOrder) {
                    this.deliveryState = this.selectedOrder;
                }
            }

            let canceledStatus = ['Canceled', 'PaymentRejected', 'DeliveryIssue']
            let status = this.deliveryState.order.status;
            this.isCanceled = canceledStatus.includes(status);
            this.cancelMessage = status == 'PaymentRejected' ?
                'Payment for this order has been rejected' : (
                    status == 'PendingPayment' ? 'Order not payed' : 'Order has been canceled'
                );
            this.successRes = true;
        }, err => {
            this.isLoading = false;
            this.notFoundRes = true;
        });
    }
    isPassedStatus(statusToCheck) {
        let allStatus = ['Accepted', 'Preparing', 'Prepared', 'PickedUp', 'Arrived', 'PendingPaymentFromDriver', 'Completed'];

        let osIndex = _.indexOf(allStatus, this.deliveryState.order.status);
        let sIndex = _.indexOf(allStatus, statusToCheck);
        return sIndex <= osIndex;


    }
    mapClick() {
        this.mapRouter.navigate(['/map/' + this.orderNo.trim()]);
    }
    getImage(date, status) {
        if (status == this.deliveryState.order.status)
            return this.loadImg;
        else if (this.isPassedStatus(this.deliveryState.order.status))
            return this.checkedImg
        else
            return this.disabledImg;
    }

    onBack() {
        if (this.orderId)
            this.mapRouter.navigate(['/']);
        else
            this.utils.goBack();
    }
    getOrderData(orderData) {
        this.orderNo = orderData && orderData.orderNo;
        this.order = orderData;
    }
}
