import { Component, OnInit, ViewEncapsulation, EventEmitter, Output, Input } from '@angular/core';
import { Cart } from '../cart';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ApiService } from '../api.service';
import { CartService } from '../cart.service';
import { governate, CountryListDto } from '../models';

@Component({
  selector: 'app-country-selector',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './country-selector.component.html',
  styleUrls: ['./country-selector.component.css']
})
export class CountrySelectorComponent implements OnInit {
  @Output()
  countryChange = new EventEmitter();
  // @Output()
  // cityChange = new EventEmitter();
  isLoading:boolean = false
  closeResult: string;
  governates: governate[] = [];
  countries: CountryListDto[] = [];
  isCountry:boolean = true;
  searchText: string;
  results = [];
  get cart(): Cart{
    return this.cartService.getCart();
  }
  areaId: number;
  nextCallback:()=>void
  constructor(public modal:NgxSmartModalService, private srvApi : ApiService, private cartService: CartService) { }

  selectCountry(cb?:()=>void) {
    this.searchText = "";
    this.isLoading = true;
    this.modal.getModal('mSelectCountry').open();  
    this.getCountries();
    this.isCountry = true;
    this.nextCallback = cb;
  }

  selectCity(cb?:()=>void) {
    this.searchText = "";
    this.isLoading = true;
    this.modal.getModal('mSelectCountry').open(); 
    this.getGovernates(); 
    this.isCountry = false;
    this.nextCallback = cb;
  }

  ngOnInit() {
  }

  getCountries(){
    this.srvApi.getCountries().subscribe(countries=>{
      this.countries = countries;
      this.isLoading = false;
    });
  }

  getGovernates(){
    this.srvApi.getGovernate(this.cart.orderInfo.pickupAddress.countryId).subscribe(gov=>{
      this.isLoading = false;
      if(!gov){
        gov = {items: [],totalCount:0,pageCount:0};
        this.onSaveCity({id : -1, name: {ar: 'مدينة', en: 'City'}} as governate); // if no governrate, popup should closed and redirect to second screen
      }
      this.governates = gov.items;
    });
  }

   onSaveCountry(country : CountryListDto){
    this.modal.getModal('mSelectCountry').close();
    if(country.id != this.cart.orderInfo.pickupAddress.countryId)
      this.cartService.setCountry(country);
    this.countryChange.emit(country.id.toString());
    if(this.nextCallback)
      this.nextCallback();
    this.nextCallback = undefined;
  }

  onSaveCity(governate: governate){
    this.cartService.setGovernate(governate);
    //this.cityChange.emit(governate.id.toString());
    this.modal.getModal('mSelectCountry').close();
    if(this.nextCallback)
      this.nextCallback();
    this.nextCallback = undefined;
  }

}