import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'iknLocalized',
  pure:false
})
export class IknLocalizedPipe implements PipeTransform {

  constructor(private translate: TranslateService) {
    
  }
  transform(value: any, ...args: any[]): any {
    if(!value)
      return '';
    if(this.translate.currentLang == 'ar')
      return value.ar || value.en;
    else
      return  value.en || value.ar;
  }

}
