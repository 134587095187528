import { Component, OnInit, ViewEncapsulation, EventEmitter, Input, Output, ChangeDetectorRef } from '@angular/core';
import { CartService } from '../cart.service';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-substitution',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './substitution.component.html',
  styleUrls: ['./substitution.component.css']
})
export class SubstitutionComponent implements OnInit {
  closeResult: string;
  selectedSubstituation: string = '';
  results = [];
  nextCallback:()=>void
  constructor(public modal:NgxSmartModalService, private cartService: CartService) { }

  selectSubstitution(cb?:()=>void) {
    this.modal.getModal('mSelectSubstitution').open();
    this.selectedSubstituation = this.cartService.getCart().orderInfo.substitution;
    if(!this.selectedSubstituation)
      this.selectedSubstituation = 'recommend';
    this.nextCallback = cb;
  }

  ngOnInit() {
  }

  onSave(){
    this.cartService.setSubstitution(this.selectedSubstituation);
    this.modal.getModal('mSelectSubstitution').close();
    if(this.nextCallback)
      this.nextCallback();
    this.nextCallback = undefined;
  }
}
