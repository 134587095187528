<div class="page-heading">
  <div class="heading-l">
    <div class="title"><a class="back color-primary" (click)="utils.goBack()"><i class="fa fa-chevron-left"></i></a></div>
  </div>
  <div class="heading-l heading-c">
      <div class="title font-extraBold">{{'Track Order' | translate}}</div>
  </div>
  <div class="heading-r"></div>
</div>
  <div style="position: relative;">
      <agm-map [latitude]="lat" [fitBounds]="true" [longitude]="lng" [zoom]="zoom" style="height: 100vh;width:100%" #agMap>
        <agm-marker [agmFitBounds]="true"
        [iconUrl]="this.pickupLocation.icon"
        [latitude]="this.pickupLocation.location?.latitude" [longitude]="this.pickupLocation.location?.longitude"></agm-marker>
        <agm-marker [agmFitBounds]="true"
        [iconUrl]="this.truckLocation.icon"
        [latitude]="this.truckLocation.location?.latitude" [longitude]="this.truckLocation.location?.longitude"></agm-marker>
        <agm-marker [agmFitBounds]="true"
        [iconUrl]="this.state.icon"
        [latitude]="this.state.location?.latitude" [longitude]="this.state.location?.longitude">
      </agm-marker>
      </agm-map>
  </div>
  <div class="map-container">
    <div class="map-no-margin font-bold">
      <h6 class="map-no-margin">{{'Tracking Details' | translate}}</h6>
      <div class="margin-top-10"><span class="map-title">{{'Order ID' | translate}}: </span><span class="map-title">{{this.orderNo}}</span></div>
      <div class="margin-top-10"><span class="map-title">{{'Driver Name' | translate}}: </span><span class="green-color f-w-400 map-underling">{{this.deliveryState.order?.driverName | iknLocalized}}</span></div>
      <div class="margin-top-10"><span class="map-title">{{'Contact' | translate}}: </span><span class="green-color f-w-400 map-underling"><a [href]="'tel://' + this.deliveryState.order?.driverPhone">{{this.deliveryState.order?.driverPhone}}</a></span></div>
    </div>
    <div class="map-address">
      <div class="map-inner-address">
        <p class="map-title m-b-5 font-bold">{{'Delivery Address' | translate}}</p>
        <p class="no-margin-top color-grey">{{this.deliveryState?.order?.pickupLocation?.addressLine1}}</p>
      </div>
      <div class="map-inner-time ">
        <p class="map-title t-center m-b-5 color-grey ">{{'Arrived Around' | translate}}</p>
        <p class="t-right green-color f-w-400 no-margin-top map-date">{{deliveryState.deliveredAt| iknDate:'tf'}}</p>
      </div>
    </div>
</div>
