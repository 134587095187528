<div class="ikn-modal country-modal">
    <ngx-smart-modal #mSelectCountry identifier="mSelectCountry">
      <div class="comp-country">
        <button class="dismiss-btn font-bold" (click)="modal.getModal('mSelectCountry').close()">
          <i class="fa fa-times"></i>
        </button>
        
        <h1 class="ikn-modal-header font-extraBold">
          <span *ngIf="isCountry">{{'Choose your country' | translate}}</span>
          <span *ngIf="!isCountry">{{'Choose your city' | translate}}</span>
        </h1>
    
        <div class='col'>
          <div class="wrap font-extraBold">
            <div class="search">
                <button type="button" class="search_button">
                    <i class="fa fa-map-marker"></i>
                </button>
                <input type="text" class="search_term" placeholder="{{(isCountry ? 'Search for country' : 'Search for city')|translate}}" [(ngModel)]="searchText">
            </div>
          </div>
    
            <!-- <div class="input-wrapper ">
              <input type="text" class="" placeholder="" [(ngModel)]="searchText">
              <i class="im im-search"></i>
            </div> -->
          </div>
        <div class='country-selector-container font-extraBold'>
          <div iknLoader [isActive]='isLoading' class="loader-container"></div>
          <div name="selected" *ngIf="!isLoading && isCountry">
            <p class="country text-right" *ngFor="let country of countries | filter:searchText"
                    (click)="onSaveCountry(country)">
                    <span class="flag-icon flag-icon-{{country.code2  | lowercase}} flag-icon-squared"></span>
                    {{country.name | iknLocalized}}
                </p>
          </div>
          <div name="selected" *ngIf="!isLoading && !isCountry">
            <p class="country text-right" *ngFor="let city of governates | filter:searchText"
            (click)="onSaveCity(city)">
                {{city.name | iknLocalized}}
            </p>
          </div>
        </div>
      </div>
    </ngx-smart-modal>
</div>
    