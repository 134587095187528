<!-- <ngx-smart-modal #mMenuAlert identifier="mMenuAlert" [dismissable]="false"> -->
<ngx-smart-modal #mMenuAlert identifier="mMenuAlert" [dismissable]="true">
    <div class="menu-alert font-bold" style="background-color: #FFFFFF;">
        <!-- <button class="dismiss-btn color-primary" (click)="close()"></button> -->
        <!-- <div class="margin-top-10"></div> -->
        <!-- <div class="img-container">
            <img src="{{'imp_img' | translate}}">
        </div> -->
        <!-- <h1 class="font-extraBold centered">{{'Important' | translate}}</h1> -->
        <div class="margin-top-10"></div>
        <!-- <img src="/assets/images/helal.png"> -->
         <!-- <h1 class="font-extraBold centered notice">{{'Notice' | translate}}</h1>  -->
         <h1 class="font-extraBold centered notice">   </h1> 
        <div class="margin-top-10"></div>
        <p [innerHTML]="msg|iknLocalized" class="text-left"></p>
        <div style="margin-bottom: 150px;" ></div>
        <div class="m-t-20"  ></div>
        <div class="form-group btn-container text-center"  >
            <button class="btn btn-continue btn-center" type="submit" (click)="close()"> {{'Continue' | translate}}
            </button>
        </div>
    </div>
</ngx-smart-modal>