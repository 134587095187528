<app-top-header class='top-bar-container' bgStyle="solid" screenTitle="Forget Password"></app-top-header>
<div class="page-content px-4 my-4">


  <form [formGroup]="submitForm" (ngSubmit)="forgetPassword()">

    <div iknLoader [isActive]='isLoading' class="loader-container"></div>
    <div *ngIf="!isLoading">
      <div class="mt-4">
        <h3 class="m-0 w-100  f-boldest">
            {{'E-Mail' | translate}}
        </h3>
    </div>
      <div class="input-wrapper d-flex align-items-center justify-content-between">
        <div class="flex-grow-1">
            <input type="email" [(ngModel)]="email" class="" placeholder="johndoe@gmail.com"
                formControlName="email" required>
                <div *ngIf="(submitted || f.email.touched) && f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">{{'E-mail is required'|translate}}</div>
                  <div *ngIf="f.email.errors.email">{{'Invalid E-mail'|translate}}</div>
                </div>
        </div>
    </div>
     
    <div class="mt-4 d-flex flex-column justify-content-between align-items-center">
      <button class="btn btn-primary bg-primary px-5" (click)="forgetPassword()">
          {{'Forget Password' | translate}}
      </button>
  </div>

    </div>

</form>

</div>
<app-footer></app-footer>

