<app-top-header class='top-bar-container' bgStyle="solid" screenTitle="Checkout"></app-top-header>
<div class="page-content px-2 ">
    <div iknLoader [isActive]='isLoading || isOrderLoading' class="loader-container"></div>
    <div *ngIf="!isLoading">
        <div *ngIf='isShipping' class='border-bottom'>
            <div class="f-boldest t-normal py-2 color-primary">
                {{'Shipping Charge'| translate}}
            </div>
            <div iknLoader [isActive]='isCalculatingShipping' class="loader-container pt-1         "></div>
            <div *ngIf='!isCalculatingShipping' class="t-normal pt-1">
                {{cart.shippingCharge | iknCurrency}}
                {{truck?.defaultCountry?.currencyCode | iknLocalized}}
            </div>
        </div>

        <div class="f-boldest t-normal py-2 color-primary" *ngIf="paymentMethods.length">
            {{'Choose Payment Method'| translate}}
        </div>
        <form [formGroup]="paymentForm" #frmPayment="ngForm" class="mt-2  checkout-page">
            <div class="input-group f-bold" *ngIf="paymentMethods?.length">
                <div class="row">
                    <ng-container *ngFor="let pm of paymentMethods">
                        <div class="col-6" *ngIf="!isShipping || pm.paymentMethod != 'cashOnDelivery'">
                            <input class="radio cursor-pointer" [(ngModel)]="paymentMethod" (change)="storePaymentMethod()"
                                   id="styled-radio-4{{pm.paymentMethod}}" type="radio" [value]="pm.paymentMethod"
                                   formControlName="paymentMethod">
                            <label for="styled-radio-4{{pm.paymentMethod}}" class='cursor-pointer radio-label-st'>{{pm.name | iknLocalized}}</label>
                            <div class="m-b-5"></div>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="submitted && fp.paymentMethod.errors" class="invalid-feedback">
                    <div *ngIf="fp.paymentMethod.errors.required">{{'Payment Method is required'|translate}}</div>
                </div>
            </div>
        </form>

        <div class="f-boldest t-normal mt-2 py-2 color-primary" *ngIf="paymentMethods.length">
            {{'Contact Information'| translate}}
        </div>
        <form [formGroup]="contactForm" #frmContact="ngForm" class="mt-2  checkout-page">
            <div class="input-group f-bold">
                <div class="input-wrapper  no-border">
                    <div class="mb-3">
                        <label class="m-0">{{"Name" | translate}}</label>
                        <input type="text" (change)="storeAddress()" class="" placeholder="{{'Full Name' | translate}} *"
                               [(ngModel)]="pickupAddress.contactName" formControlName="contactName">
                        <div *ngIf="submitted && fc.contactName.errors" class="invalid-feedback">
                            <div *ngIf="fc.contactName.errors.required">{{'Name is required'|translate}}</div>
                        </div>
                    </div>
                </div>
                <div class="input-wrapper" *ngIf="allowEmail">
                    <div class="mb-3">
                        <label class="m-0">{{"Email" | translate}}</label>
                        <input type="text" (change)="storeAddress()" class="" placeholder="{{'E-mail' | translate}}"
                               [(ngModel)]="pickupAddress.contactEmail" formControlName="contactEmail">
                        <div *ngIf="submitted && fc.contactEmail.errors" class="invalid-feedback">
                            <div *ngIf="fc.contactEmail.errors.required">{{'E-mail is required'|translate}}</div>
                            <div *ngIf="fc.contactEmail.errors.email">{{'Invalid E-mail'|translate}}</div>
                        </div>
                    </div>
                </div>

                <div class="input-wrapper" *ngIf="allowMembershipNumber">
                    <div class="mb-3">
                        <label class="m-0">{{"Membership Number" | translate}}</label>
                        <input type="text" class="" placeholder="{{'Membership Number' | translate}}" (change)="storeAddress()"
                               formControlName="membershipNo" [(ngModel)]="cart.orderInfo.membershipNo">
                    </div>
                </div>
                <div class="input-wrapper no-border">
                    <div class="mb-3">
                        <label class="m-0">{{'Phone' | translate}}</label>
                        <ngx-intl-tel-input [cssClass]="'phone-input'" [preferredCountries]="preferredCountries"
                                            [enableAutoCountrySelect]="false" [enablePlaceholder]="true"
                                            [searchCountryPlaceholder]="'Search Country' | translate" [searchCountryFlag]="true"
                                            [searchCountryField]="[SearchCountryField.All]" [selectFirstCountry]="false"
                                            [selectedCountryISO]="selectedCountryCode" [tooltipField]="TooltipLabel.Name" [phoneValidation]="true"
                                            [separateDialCode]="true" [(ngModel)]="phoneNumber" id="phone-id" name="contactPhone"
                                            formControlName="contactPhone" (ngModelChange)="validatePhoneNumber()"></ngx-intl-tel-input>
                        <div *ngIf="submitted && (fc.contactPhone.errors )" class="invalid-feedback">
                            <div *ngIf="fc.contactPhone.invalid ">{{'Invalid Phone number'|translate}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="input-wrapper no-border" *ngIf="cart.serviceType_ex =='pickup'">
            <label class="m-0">{{"Car model and color" | translate}}</label>
            <input type="text" (change)="storeAddress()" class="" placeholder="{{'Car model and color' | translate}}"
                   [(ngModel)]="cart.orderInfo.fileNumber">
        </div>

        <div class="mt-3 ">
            <div class="f-boldest t-normal py-2 color-primary" [ngSwitch]="cart.orderInfo.serviceType">
                <span *ngSwitchDefault>{{"Delivery Address" | translate}}</span>
                <span *ngSwitchCase="'pickup'">{{"Pickup Address" | translate}}</span>
                <span *ngSwitchCase="'catering'">{{"Catering Address" | translate}}</span>
            </div>
            <div class="mt-2 t-normal  pb-2" *ngIf="cart.orderInfo.serviceType  != 'pickup'">
                <div class="py-0">
                    <span class="f-bold">{{'Address Title' | translate}}: </span> <span>
                        {{pickupAddress?.title}}
                    </span>
                </div>
                <div class="py-0">
                    <span class="f-bold">{{'Block' | translate}}: </span> <span>
                        {{pickupAddress?.blockNo}}
                    </span>
                </div>
                <div class="py-0">
                    <span class="f-bold">{{'Street Name/Number' | translate}}: </span> <span>
                        {{pickupAddress?.street}}
                    </span>
                </div>
                <div class="py-0" *ngIf="pickupAddress?.type == 'house'">
                    <span class="f-bold">
                        {{
'House Number' |
          translate
                        }}:
                    </span> <span> {{pickupAddress?.buildingNo}}</span>
                </div>
                <div class="py-0" *ngIf="pickupAddress?.type != 'house'">
                    <span class="f-bold">
                        {{
'Building Number' |
          translate
                        }}:
                    </span> <span> {{pickupAddress?.buildingNo}}</span>
                </div>
                <div class="py-0" *ngIf="pickupAddress?.type != 'house'">
                    <span class="f-bold">
                        {{
'Floor Number' |
          translate
                        }}:
                    </span> <span> {{pickupAddress?.floorNo}}</span>
                </div>
                <div class="py-0" *ngIf="pickupAddress?.type == 'building'">
                    <span class="f-bold">
                        {{
'Flat Number' |
          translate
                        }}:
                    </span> <span> {{pickupAddress?.flatNo}}</span>
                </div>
                <div class="py-0" *ngIf="pickupAddress?.type == 'work'">
                    <span class="f-bold">
                        {{
'Office Number' |
          translate
                        }}:
                    </span> <span> {{pickupAddress?.flatNo}}</span>
                </div>
                <div class="py-0 d-flex align-items-center justify-content-between">
                    <!-- <div><span class="f-bold">{{'Phone' | translate}}: </span> <span> {{pickupAddress?.contactPhone}}</span></div> -->
                    <div class="color-primary cursor-pointer"
                         [routerLink]="!cart.orderInfo?.pickupAddress?.selectedAddressId ? ['/pre-checkout'] : ['select-address']">
                        {{'Change' | translate}}
                    </div>
                </div>


            </div>
            <div class="mt-2 t-normal  pb-2" *ngIf="cart.orderInfo.serviceType == 'pickup'">
                <div class="py-0">
                    <span class="f-bold">{{'Branch' | translate}}: </span> <span>
                        {{
cart.$branch?.name |
          iknLocalized
                        }}
                    </span>
                </div>
                <div class="py-2">
                    <span class="f-bold">{{'Address' | translate}}: </span>
                    <span>
                        {{cart.$branch?.location.address | iknLocalized}} <a target="_blank"
                                                                             href="http://maps.google.com/?q={{cart.$branch?.location.location.lat}}, {{cart.$branch?.location.location.lng}}">
                            <i class="fa fa-lg fas fa-map-marker text-primary"></i>
                        </a>
                    </span>
                </div>
            </div>
        </div>
        <div class="f-boldest t-normal py-2 color-primary" *ngIf="order">
            <span>{{"Order Details" | translate}}</span>
        </div>
        <div class="cart-item" *ngIf="order">
            <div class="d-flex flex-column" *ngFor="let item of order?.items">
                <div class="d-flex">
                    <div class="mx-2" style="min-width:60px;width:60px;height:60px;border-radius: 5px;">
                        <img [src]="item?.item.mainPic" style="width:100%;height:100%;border-radius: 5px" />
                    </div>
                    <div class="flex-grow-1 d-flex flex-column  justify-content-between">
                        <div class="d-flex align-items-center justify-content-between">
                            <h6>{{item.item.title | iknLocalized}}</h6>
                            <h6>
                                {{item.total | iknCurrency}}
                                {{truck?.defaultCountry?.currencyCode | iknLocalized}}
                            </h6>
                        </div>
                    </div>

                </div>
                <div *ngIf="item.extras && item.extras.length">
                    <h6 class="py-2">{{'Extras' | translate}}</h6>
                </div>
                <div *ngFor="let extra of item.extras" class="d-flex">
                    <div class="d-flex w-100">
                        <div class="mx-2" style="width:45px;height:45px;border-radius: 5px">
                            <img [src]="extra.extraItem.mainPic || item?.item.mainPic"
                                 style="width:100%;height:100%;;border-radius: 5px" />
                        </div>
                        <div class="flex-grow-1 d-flex flex-column">
                            <div class="d-flex align-items-center justify-content-between">
                                <h6>{{extra.extraItem.title  | iknLocalized}}</h6>
                                <h6>
                                    {{extra.extraItem.price * extra.extraItem.qty | iknCurrency}}
                                    {{truck?.defaultCountry?.currencyCode | iknLocalized}}
                                </h6>
                            </div>
                        </div>

                    </div>
                </div>
                <hr class="m-0 my-2" />
            </div>
        </div>
        <form [formGroup]="termsForm" #frmTerms="ngForm" class="mt-4 t-normal" *ngIf="allowTerms">
            <input class="d-none" id="accept_terms" type="checkbox" formControlName="acceptTerms" [(ngModel)]="acceptTerms">
            <label for="accept_terms" class="t-normal f-bold color-primary" *ngIf="allowTerms">
                {{'I agree to' | translate}}
                <a [routerLink]="['/terms']" class="f-boldest color-primary">{{'the terms and conditions' | translate}}</a>

                <!-- {{'srv_' + cart.orderInfo.serviceType + '_service' | translate}} -->
            </label>
            <div *ngIf="submitted && ft.acceptTerms.errors" class="invalid-feedback">
                <div *ngIf="ft.acceptTerms.invalid">{{'You must agree to terms and conditions'|translate}}</div>
            </div>
        </form>

    </div>
</div>
<app-footer></app-footer>

<div class="page-btn f-boldes t-large" *ngIf="!isLoading" [@slideInUpOnEnter]>
    <a class="btn btn-primary bg-primary btn-cart" (click)="submitOrder()">
        <span *ngIf="isOpen !== undefined">
            <span *ngIf="isOpen || cart.orderInfo.pickupTime">{{'PLACE THIS ORDER' | translate}}</span>
            <span *ngIf="isOpen === false && !cart.orderInfo.pickupTime">
                {{'submit_closed' | translate}}
                {{'operating_hours_warning' | translate:operatingHours}}
            </span>
        </span>
    </a>
</div>



<div >
    <ngx-smart-modal 
                     #mconfirmPhonenumber identifier="mphoneValidator"
                     [dismissable]="false">

        <div class="modal-header">
    
        </div>
        <div class="modal-body">
        
            <div class="popap">
                <div class="alert alert-danger" role="alert" *ngIf="!isverificationCodeValid">
                    {{'Verification code not valid' | translate }}
                </div>
                <br/> <br/> 
                      <h3> {{'OTP Verification' | translate }}  </h3>
                      <p> {{'Please enter the verification code sent' | translate}} <br />{{'To' | translate }}    {{ phoneNumber?.internationalNumber }}</p>
                 <span id="timer">{{ recordingTimer }}</span><br/> 
                    <div class="codeinputcontainer">
                        <input class="form-control  codeinput" type="text"  name="code" required   (ngModelChange)="changeCode()" [(ngModel)]="verificationCode" />
                    </div>
                    <p> {{'Didnt receive OTP' | translate}}  <a class="resend" (click)="sendVerificationCode()">{{'Resend OTP' | translate}}   </a> </p>
                    <button class="verifycodebtn" (click)="verifyThePhoneCode()">  {{'Verify' | translate}}</button>
              </div>
          
        </div>
        <div class="modal-footer"></div>
      
</ngx-smart-modal>
</div>
