import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UtilsService } from '../utils.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../api.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { NotifyService } from '../notify.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  submitForm: FormGroup;
  submitted = false;
  isLoading:boolean = false;
  showPass:boolean = false;
  logo:string= '';
  confirmPassword:string ='';
  
  data = {
    newPassword: '',
    resetCode: '',
    email: '',
    role: 'customer',
    appKey: 'customer.web',
  }

  constructor(public utils:UtilsService,
      private router:Router,
      private api:ApiService, 
      private translate: TranslateService,
      private formBuilder: FormBuilder,
      private toaster:ToastrService,
      private route:ActivatedRoute,
      private notify:NotifyService) {
        this.route.queryParams.subscribe(params => {
          this.data.email = params['mail'];
          this.data.resetCode = params['code'];
          this.data.role = params['role'];
          if(!this.data.email || !this.data.resetCode){
            this.toaster.error(this.translate.instant('Invalid reset request'));
            this.router.navigate(['/login']);
          }
        });
  }

  showPassword(){
    this.showPass = !this.showPass;
  }
  
  ngOnInit() {
    this.utils.fbTrack('ResetPassword');
    this.utils.snapchatTrack('SIGN_UP');
    
    //$('.page-side').removeClass('sm-hidden');
    this.submitForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    });

    this.api.checkIfAuthenticated().subscribe(res => {
      if (res && this.api.getAuthUser().userId != -1)
        this.router.navigate(['/home']);
    });

    this.api.viewSBOptionsDetails().subscribe(op => {
      this.logo = op.simpleBrokerOptions && op.simpleBrokerOptions.logo || environment.logo;
    });
  }

   // convenience getter for easy access to form fields
   get f() { return this.submitForm.controls; }

   resetPassword(){
    this.submitted = true;
     // stop the process here if form is invalid
    if (this.submitForm.invalid)
    return;

    this.isLoading = true;

    this.api.resetPassword(this.data).subscribe(res => {
      this.isLoading = false;
      this.router.navigate(['/login']);
      this.toaster.success(this.translate.instant('reset_pass_success_msg'));
    }, err => {
      this.isLoading = false;
      this.notify.sweet_error(err); 
    });
  }

}