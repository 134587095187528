<div class="ikn-modal">
<ngx-smart-modal #mAskForMembership identifier="mAskForMembership" [dismissable]="true">
    <div class="membership-modal">
        
        <div style="height: 20px;"></div>

        <div class="text-center">
            <img class="membership-logo" [src]="sbOptions?.logoPic" />
        </div>
        <div class="seperator"></div>

        <div class="text-right p-d-10">
           
            
            <div class="" >
                <div class="form-group">
                    <input  class="form-control" type="text" [(ngModel)] = "memberInfo.fileNumber" placeholder="{{'Enter File Number' | translate}}"/>
                </div>
                <div class="form-group">
                    <input class="form-control" type="text" [(ngModel)] = "memberInfo.civilId" placeholder="{{'Enter Civil Id' | translate}}"/>
                </div>
                <div class="form-group btn-container text-center">
                    <button class="btn btn-success" style="width:100%" type="submit"
                    (click)="onSave()">
                    <span *ngIf="!isLoading">{{'Continue' | translate}}</span>
                    <div class="lds-ellipsis" *ngIf="isLoading">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </button>
                </div>
            </div>
        </div>
        
    </div>
</ngx-smart-modal>  
</div>