<app-top-header class='top-bar-container' bgStyle="solid" screenTitle="Checkout"></app-top-header>
<div class="page-content px-4 my-4">
<div iknLoader [isActive]='isLoading' class="loader-container"></div>

  <div *ngIf='!isLoading'>
    <div class="d-flex flex-column truck-info-min">
        <div class="d-flex justify-content-between align-items-top" [routerLink]="isShippingMode  ? [] : ['/service']"
        >
            <h3 *ngIf='!isShippingMode' class="text-nowrap m-0">{{'Service and area' | translate}}: </h3>
            <h3 *ngIf='isShippingMode' class="text-nowrap m-0">{{'Service and location' | translate}}: </h3>
            <div class="px-2 flex-grow-1">
                <span

                    *ngIf="(isShippingMode && !cart.orderInfo.pickupAddress.countryId)|| (!isShippingMode && (!cart.orderInfo.pickupAddress.areaId || !cart.serviceType_ex))">{{'Choose Service - Area' | translate}}</span>
                <span
                    *ngIf="!isShippingMode && cart.orderInfo.pickupAddress.areaId && cart.serviceType_ex">{{'srv_' + cart.serviceType_ex | translate}}
                    -
                    {{cart.orderInfo.pickupAddress.areaName | iknLocalized}}</span>

                    <span
            *ngIf="isShippingMode && cart.orderInfo.pickupAddress.countryId &&  cart.serviceType_ex"><b class="service-type-info">{{'srv_' + cart.serviceType_ex | translate}}</b>


            <span class='px-2'>{{cart.orderInfo.pickupAddress.countryName | iknLocalized}} - {{cart.orderInfo.pickupAddress.governateName | iknLocalized}}</span>
            </span>
            </div>
            <div *ngIf='!isShippingMode'>
                <span><i class="fa fa-angle-right fa-2x"></i></span>
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-center"
            [class.disabled]="
            isShippingMode ||
            !cart.serviceType_ex ||
            cart.serviceType_ex == 'soonest_delivery' || cart.serviceType_ex == 'pickup'"
            [routerLink]="['/time-date']">
            <div class="d-flex no-wrap">
                <h3 class="text-nowrap m-0">{{'Time and date' | translate}}: </h3>
            </div>
            <div class="px-2 flex-grow-1 d-flex align-items-center">
                <span *ngIf="!cart?.orderInfo.pickupTime">
                    {{'Choose Time - Date' | translate}}
                </span>
                <span *ngIf="cart?.orderInfo.pickupTime">
                    {{cart?.orderInfo.pickupTime | iknDate: 'day'}}
                    {{cart?.orderInfo.pickupTime | iknDate: 'tf'}} - {{cart?.orderInfo.pickupTimeTo | iknDate: 'tf'}}
                </span>
            </div>
            <div *ngIf="!isShippingMode && cart.serviceType_ex && cart.serviceType_ex != 'soonest_delivery'
            && cart.serviceType_ex != 'pickup' ">
                <span><i class="fa fa-angle-right fa-2x"></i></span>
            </div>
        </div>
    </div>
    <hr />
    <div  *ngIf='cart.serviceType_ex != "catering"'>
        <div class="d-flex justify-content-between align-items-center">
            <div class=" flex-grow-1 btn btn-outline-secondary py-3" (click)="continueAs = 'guest'"
                [class.active]="continueAs == 'guest'">
                {{'Continue As Guest' | translate}}
            </div>
            <div class="mx-3"></div>
            <div class="flex-grow-1 btn btn-outline-secondary py-3" (click)="continueAs = 'login'"
                [class.active]="continueAs == 'login'">
                {{'Login' | translate}}
            </div>

        </div>
    </div>
    <div class="my-4"></div>
    <app-login-part [returnTo]="['/select-address', {returnTo: '/checkout'}]" *ngIf="continueAs == 'login'">
    </app-login-part>
    <div class="my-4"></div>
    <app-address-part *ngIf="continueAs == 'guest'" #address
    (areaChanged)='onAreaChanged($event)'
    ></app-address-part>
  </div>
</div>
<div class="page-btn f-boldest t-large" [@slideInUpOnEnter] *ngIf="!isLoading && !isslotsLoading &&continueAs == 'guest'">
    <a (click)="cmpAddress.saveAddress(cart.orderInfo.pickupAddress) && save()"
        class="btn btn-primary bg-primary btn-cart">{{'Continue' | translate}}</a>
</div>
<app-footer></app-footer>
