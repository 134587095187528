import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'iknCurrency'
})
export class IknCurrencyPipe implements PipeTransform {
  transform(value: any): string {

    if (!value) {
      value = 0;
    }
    let dc = environment.decimalPoints;
    if(!dc)
      dc = 3;
    return value.toFixed(dc);
  }
}
