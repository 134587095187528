<app-top-header class='top-bar-container' bgStyle="solid" screenTitle="My Orders"></app-top-header>
<div class="page-content">
  <div class="menu-block">
    <div iknLoader [isActive]='isLoading' class="loader-container"></div>
    <h6 class="font-extraBold text-center" *ngIf="!lstOrders?.length && !isLoading">
      <div class="m-t-20"></div>
      {{'You don\'t have any orders yet' | translate}}
    </h6>
    <div class="d-flex flex-column" *ngFor="let order of lstOrders">
      <div class="w-100 p-2">
        <div class="border w-100 px-2">
          <div class="margin-top-10"></div>
          <div class="d-flex">
            <div class="">
              {{'Order ID' | translate}} - {{order.orderNo}}
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="">
              {{'Order Date' |translate}}: {{order.submittedAt | iknDate:'df'}}
            </div>
            <div class="">
              {{'Order Time' |translate}}: {{order.submittedAt | iknDate: 'tf'}}
            </div>
          </div>
          <div class="text-center">
            {{'Order Status' |translate}}: {{
                      (order.status == 'PendingPayment' && order.serviceType == 'catering' ?
                      'OrderConfirmed' :
                      order.status) | translate}}
          </div>
          <div class="d-flex justify-content-between color-primary mt-1">
            <a class='cursor-pointer color-primary' [routerLink]="['/my-orders/' + order.id]">{{'Order Details' | translate}}</a>
            <a  class='cursor-pointer color-blue' *ngIf='order.status == "PendingPayment"'
            [href]="order.payment.paymentUrl">{{'Continue to payment' | translate}}</a>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
