import { Pipe, PipeTransform, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import * as moment from 'moment';

@Pipe({
  name: 'iknDate',
  pure:false,
})
export class IknDatePipe implements PipeTransform {

  constructor(@Inject(TranslateService) private srvTrans:TranslateService)
  {
  }
  transform(date:any, formatType:any): any {
    var formats = {
      df: "dd/MM/yyyy",
      tf: "hh:mm a",
      dtf: "dd/MM/yyyy hh:mm a",
      tdf: "hh:mm a dd/MM/yyyy",
      dm: "d MMM",
      dmy : "d MMM , yyyy",
      dmt: "d MMM hh:mm a",

      ddm: "EEEE, d MMM",
      ddmy : "EEEE, d MMM , yyyy",
      ddmt: "EEEE, d MMM hh:mm a",
      day: function(date, trans:TranslateService){
        if(!date)
          return '';
        let days =  moment(date).startOf('day').diff(moment(new Date()).startOf('day'), 'days');
        if(days == 0)
          return trans.instant('Today');
        else if(days == 1)
          return trans.instant('Tomorrow');
        else
          return this.transform(date, 'f:EEEE');
      },
      daynear: function(date, trans:TranslateService){
        if(!date)
          return '';
        let days =  moment(date).startOf('day').diff(moment(new Date()).startOf('day'), 'days');
        if(days == 0)
          return trans.instant('Today');
        else if(days == 1)
          return trans.instant('Tomorrow');
        else if (days == 2)
          return trans.instant('After Tomorrow');
        else
          return trans.instant('DAYS_DIFF', {days: days});
      },
      getFormat: function (format) {
        var realFormat;
        var isCustom = false;
        if (format) {
          isCustom = _.startsWith(format, 'f:');
          if (isCustom) {
            realFormat = format.substring(2);
          } else
            realFormat = formats[format];
        }
        //if we can't find proper format default to Date only format
        realFormat = realFormat || formats.df;

        
        return realFormat;// realFormat.trim();
      }
    };
    let locale = this.srvTrans.currentLang == 'ar' ? 'ar' : 'en-US';
    let datePipe = new DatePipe(locale);
    if (!date)
      return '';
    var realDate = this.getAsDate(date);
    var realFormat = formats.getFormat(formatType);
    if(!_.isFunction(realFormat))
        return datePipe.transform(realDate, _.trim(realFormat));
    else
      return realFormat.bind(this)(realDate, this.srvTrans);
  }

  getAsDate(val){
    if (val && val.dateGeg) {
      return val.dateGeg;
    } else if (val instanceof Date)
      return val;
    else {
      try {
        return new Date(val);
      } catch (err) {
        return undefined;
      }
    }
  }
}
