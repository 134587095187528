import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { UtilsService } from '../utils.service';
import { TranslateService } from '@ngx-translate/core';
import { CartService } from '../cart.service';
import { CartItem, CartExtraItem, MenuExtraItem } from '../models';
import * as moment from 'moment';
import * as _ from 'lodash'

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.css']
})
export class OrderHistoryComponent implements OnInit {

  lstOrders: any;
  isLoading: boolean = false;

  constructor(public utils: UtilsService, private api: ApiService
    ,private router:Router, private srvTrans : TranslateService
    ,private srvCart: CartService ) { }

  ngOnInit() {
    this.utils.fbTrack('MyOrders');

    if(!this.api.isLoggedIn)
      this.router.navigate(['/login']);
    
    this.isLoading = true;
    this.api.getMyOrdrers().subscribe(res => {
      if(res && res.items)
        this.lstOrders = res.items;
      this.isLoading = false;
    });
  }

  getTimeAgo(date){
    return moment(date).fromNow();
  }

  reOrder(order){
    this.isLoading = true;
    this.api.viewOrder(order.id).subscribe(res => {
      this.isLoading = false;
      this.srvCart.getCart().cartItems = [];
      _.each(res.items, item=>{
        this.api.viewItem(item.item.id).subscribe(it => {
          this.srvCart.addItem(_.assignIn(new CartItem(),{
            $item: it.itemInfo,
            extras: _.map(item.extras, ei=>{
              let res =new CartExtraItem();
              let foundCat = _.find(it.extras, ec => ec.extraCategory.id == ei.extraCategory.id);
              if(foundCat){
               let foundExtra = _.find(foundCat.extraItems, ex => ex.id == ei.extraItem.id);
               ei.catId = ei.extraCategory.id;
               ei.id = ei.extraItem.id;
               res.$extraItem = _.assignIn(foundExtra, ei);
              }
              return res;
            }),
            itemId: item.item.id,
            unitPrice: item.unitPrice,
            qty: item.qty,
            notes: item.notes,
          }));
        });
        this.router.navigate(['/cart']);
      })
    });
  }
}
