import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { MenuAlert, LocalizedField } from '../models';
let alreadyDisplayed = false;
@Component({
  selector: 'app-menu-alert',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './menu-alert.component.html',
  styleUrls: ['./menu-alert.component.css']
})
export class MenuAlertComponent implements OnInit {
  title: LocalizedField;
  msg: LocalizedField;

  nextCallback:()=>void
  constructor(public modal:NgxSmartModalService) { }

  showMenuAlert(cb?:()=>void, menuAlert?:MenuAlert) {
    if(alreadyDisplayed)
      {
        if(cb)
          cb();
        return;
      }
    alreadyDisplayed = true;
    this.modal.getModal('mMenuAlert').open();
    this.nextCallback = cb;
    this.title = menuAlert.title;
    this.msg = menuAlert.message;
  }

  ngOnInit() {

  }

  close(){
    this.modal.getModal('mMenuAlert').close();
    if(this.nextCallback)
      this.nextCallback();
    this.nextCallback = undefined;
  }
}
