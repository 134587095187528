<div iknLoader [isActive]='isLoading' class="loader-container"></div>
<div class="page-content" >
  <app-top-header class='top-bar-container' bgStyle="solid" [screenTitle]="screenTitle | translate"></app-top-header>
    <div class="wrap px-4" *ngIf='!isLoading'>
        <!--{{selectMode}}-->
        <div class="row" *ngIf='canSelectService'>
            <div class="col-6 py-2" *ngFor="let srv of services">
                <div class="btn btn-outline-secondary w-100 t-medium"
                [ngClass]="{'active': selectedService == srv}" (click)="setService(srv)">
                    {{'srv_' + srv | translate}}
                </div>
            </div>
        </div>
        <div class=' py-2 ' *ngIf="allowShipping && selectedService != 'pickup' && !forCatering ">
          <span class="f-boldest t-normal color-primary">
            {{'Select Country'| translate}}</span>
            <span class=" px-1" *ngIf="country" (click)="openCountry()" >{{country | iknLocalized}}</span>
            <span class=" px-1" *ngIf="country" (click)="openCountry()" ><i class='fa fa-pencil cursor-pointer color-primary'></i></span>
            <span class=" px-1 " *ngIf="!country" (click)="openCountry()" >{{'Choose your country' | translate}}</span>
        </div>
        <div *ngIf="selectMode == 'areas'">
            <div class='t-normal f-boldest my-4 text-center' *ngIf='!canSelectService'>
                {{'Select Area' | translate}}
            </div>
            <div class="my-2">
                <div class="wrap font-extraBold">
                    <div class="search w-100">
                        <button type="button" class="search_button">
                            <i class="fa fa-map-marker"></i>
                        </button>
                        <input type="text" class="search_term" placeholder="{{'Search for area' | translate}}"
                            [(ngModel)]="searchText">
                    </div>
                </div>
            </div>
            <div class='t-medium'>
                <div name="selected" *ngFor="let governate of defaultCountryGovernates">
                    <div *ngIf="(governate.areas | filter:searchText) as results">
                        <div class="governate py-2 d-flex align-items-center justify-content-between border-bottom f-boldest"
                            (click)="onGovernate(governate)" [ngClass]="{'active color-primary': governate.flag}" *ngIf="results.length">
                            <a class="flex-grow-1 governatename ar-right">{{governate.name | iknLocalized}} </a>
                            <i class="anglePosition"
                                [ngClass]="{'im im-angledown': !governate.flag && !searchText, 'im im-angleup': governate.flag || searchText}"></i>
                        </div>
                        <div *ngIf="governate.flag || searchText" class=''>
                            <div class="my-2 py-2 area border-bottom" *ngFor="let area of results" (click)="saveArea(area)"
                            [ngClass]="{'active color-primary': area.id == selectedArea?.id}">
                                <a class="areaname">{{area.name | iknLocalized}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="selectMode == 'branches'">
            <div class="my-2">
                <div class="wrap font-extraBold">
                    <div class="search w-100">
                        <button type="button" class="search_button">
                            <i class="fa fa-map-marker"></i>
                        </button>
                        <input type="text" class="search_term" placeholder="{{'Search For Branch' | translate}}"
                            [(ngModel)]="searchText">
                    </div>

                </div>
            </div>
            <div class="t-medium">
                <div name="selected" *ngFor="let branch of branches">
                    <div class="py-2 d-flex align-items-center justify-content-between border-bottom f-boldest"
                    (click)="saveBranch(branch)"
                    [ngClass]="{'active color-primary' : branch.id == selectedBranch?.id}">
                        <a class="areaname">{{branch.name | iknLocalized}}</a>
                        <div class=" align-content-end">
                            <a [routerLink]="['/branch-details', branch.id ]">
                                <img src="../../assets/images/img5.png" width="20" height="20">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="selectMode == 'city'">
          <div class='t-normal f-boldest my-4 text-center' *ngIf='!canSelectService'>
            {{'Select City' | translate}}
          </div>
          <div class="my-2">
              <div class="wrap font-extraBold">
                  <div class="search w-100">
                      <button type="button" class="search_button">
                          <i class="fa fa-map-marker"></i>
                      </button>
                      <input type="text" class="search_term" placeholder="{{'Search for city' | translate}}"
                          [(ngModel)]="searchText">
                  </div>
              </div>
            <div iknLoader [isActive]='isLoadingCities' class="loader-container"></div>
          </div>
          <div class='t-medium'>
              <div name="selected" *ngFor="let governate of governates | filter:searchText">
                <div class="governate py-2 d-flex align-items-center justify-content-between border-bottom f-boldest"
                          (click)="onGovernate(governate)">
                          <a class="flex-grow-1 governatename ar-right">{{governate.name | iknLocalized}} </a>
                      </div>
              </div>
          </div>
      </div>
    </div>

    <!-- <div class="page-btn f-boldest t-large" *ngIf="selectedService && selectedArea?.id" [@slideInUpOnEnter]>
        <a (click)="save()" class="btn btn-primary bg-primary btn-cart"
        translate="Select service _ and area _" [translateParams]="{service : 'srv_' + selectedService|translate, area: selectedArea?.name}"></a>
    </div> -->
</div>
<app-country-selector (countryChange)="onCountryChange($event)"></app-country-selector>
<app-footer></app-footer>
