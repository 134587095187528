import { CategoryView, AppEventType } from './../models';
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { CartService } from '../cart.service';
import { Cart } from '../cart';
import { UtilsService } from '../utils.service';

@Component({
  selector: 'app-sub-category',
  templateUrl: './sub-category.component.html',
  styleUrls: ['./sub-category.component.css']
})
export class SubCategoryComponent implements OnInit {
  cart:Cart
  categories : CategoryView[]
  category:CategoryView;
  isLoading:boolean=false;
  constructor(private api:ApiService,
    private utils:UtilsService,
    private route:ActivatedRoute,
    public srvCart: CartService, private router: Router) {
    this.cart = srvCart.getCart();
   }
  goBack(){
    this.utils.goBack();
    return;
    if(this.category && this.category.parentCategoryId){
      this.router.navigateByUrl('/sub-category/' + this.category.parentCategoryId);
    }else{
      this.router.navigateByUrl('/');
    }
  }
  ngOnInit() {
    let that = this;
    this.isLoading=true;
    this.route.params.subscribe(params => {
      let parentId = params['id'];
      this.api.trackEvent(AppEventType.ViewCategory, Number(parentId));
      this.api.viewCategory(parentId).subscribe(cats=>{
        this.api.viewTruck().subscribe(d=>{
          this.isLoading = false;
          if(!that.utils.isNavigationAllowed(d.truck))
        {
          that.router.navigate(['/home']);
          return;
        }
        }, err=>{
          this.isLoading = false;
        })
        this.category = cats;
        this.categories = cats.subCategories;
      }, err=>{
        this.isLoading = false;
      });
    });

  }

}
