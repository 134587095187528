import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'category'
})
export class CategoryPipe implements PipeTransform {

  transform(categories: any[], searchText: string): any[] {
    if (!categories) {
      return [];
    }
    if (!searchText) {
      return categories;
    }
    searchText = searchText.toLocaleLowerCase();
    return categories.filter(it => {
      return  it.categoryInfo.title.en.toLocaleLowerCase().includes(searchText) || it.categoryInfo.title.ar.toLocaleLowerCase().includes(searchText) ;
    });
  }

}
